var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "newbreifingtile p-2 mb-4" }, [
    _c("div", { staticClass: "flip-card mb-2" }, [
      _c("div", { staticClass: "flip-card-inner" }, [
        _c(
          "div",
          { staticClass: "flip-card-front bg-primary text-white text-center" },
          [_vm._v(" + ")]
        ),
        _c(
          "div",
          { staticClass: "flip-card-back bg-third p-3" },
          [
            _c(
              "ValidationObserver",
              { ref: "observer" },
              [
                _c("validation-provider", {
                  attrs: {
                    rules: "is_not:default",
                    name: "briefing type",
                    mode: "passive",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.briefingType,
                                  expression: "briefingType",
                                },
                              ],
                              staticClass: "form-control mb-2",
                              class: errors[0] ? "is-invalid" : "",
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.briefingType = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "default" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("i18n.placeholder_select_briefing")
                                    ) +
                                    " "
                                ),
                              ]),
                              _vm._l(
                                _vm.briefingTypes,
                                function (briefing, key) {
                                  return _c(
                                    "option",
                                    { key: key, domProps: { value: key } },
                                    [_vm._v(" " + _vm._s(briefing.title) + " ")]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-block text-white",
                    on: { click: _vm.createBriefing },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("i18n.btn_create")) + " ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "p-2" }, [
      _c("h5", [_vm._v(_vm._s(_vm.$t("i18n.headline_add")))]),
      _c("p", [_vm._v(_vm._s(_vm.$t("i18n.helper_add")))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }