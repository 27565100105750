import { render, staticRenderFns } from "./PrintProductBundle.vue?vue&type=template&id=10bbad43&"
import script from "./PrintProductBundle.vue?vue&type=script&lang=js&"
export * from "./PrintProductBundle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/custom-builds/RenderThat/aws/briefing-tool-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('10bbad43')) {
      api.createRecord('10bbad43', component.options)
    } else {
      api.reload('10bbad43', component.options)
    }
    module.hot.accept("./PrintProductBundle.vue?vue&type=template&id=10bbad43&", function () {
      api.rerender('10bbad43', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/briefing/print/PrintProductBundle.vue"
export default component.exports