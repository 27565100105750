var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "row shadow bg-white mb-4 cp mx-1 mx-sm-0",
        attrs: { id: _vm.contentpiece.id },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "col-12 col-sm-2 bg-green text-white py-3 text-right cp-info",
            class: [_vm.contentpiece.ordered ? "bg-success" : "bg-primary"],
          },
          [
            _c(
              "h4",
              { staticClass: "d-inline d-sm-block float-left float-sm-none" },
              [_vm._v(" " + _vm._s(_vm.contentpiece.internal_id) + " ")]
            ),
            _c("span", { staticClass: "d-inline d-sm-block" }, [
              _c("strong", [_vm._v(_vm._s(_vm.contentpiece.user.name))]),
            ]),
            _c("span", { staticClass: "d-inline d-sm-none" }, [_vm._v(" - ")]),
            _c("span", { staticClass: "d-inline d-sm-block" }, [
              _vm._v(_vm._s(_vm.contentpiece.team)),
            ]),
            _c("span", { staticClass: "d-inline d-sm-none" }, [_vm._v(" - ")]),
            _c("span", { staticClass: "d-inline d-sm-block mb-2 small" }, [
              _c("small", [
                _vm._v(
                  _vm._s(
                    _vm._f("moment")(_vm.contentpiece.created_at, "DD.MM.Y")
                  )
                ),
              ]),
            ]),
            _vm.contentpiece.ordered
              ? _c("font-awesome-icon", {
                  staticClass: "ordered",
                  attrs: { icon: ["fas", "check"] },
                })
              : _vm._e(),
            _vm.contentpiece.ordered && _vm.contentpiece.jira_export
              ? _c("div", { staticClass: "cp-uuid" }, [
                  _c(
                    "a",
                    {
                      staticClass: "link text-white",
                      attrs: {
                        target: "_blank",
                        href:
                          "https://jira.obi.net/browse/" +
                          _vm.contentpiece.jira_export.response.key,
                      },
                    },
                    [_vm._v(_vm._s(_vm.contentpiece.jira_export.response.key))]
                  ),
                  _vm._v(
                    _vm._s(
                      _vm.contentpiece.jira_export.status != null
                        ? " - " + _vm.contentpiece.jira_export.status.name
                        : ""
                    ) + " "
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "col-12 col-sm-10 py-3 description" }, [
          _c(
            "h4",
            {
              staticClass: "cursor-pointer d-inline",
              on: {
                click: function ($event) {
                  return _vm.selectContentPiece()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.contentpiece.name) + " ")]
          ),
          _c("div", { staticClass: "cp-desc" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm._f("truncate")(
                    _vm.contentpiece.description,
                    600,
                    " [...]"
                  )
                ) +
                " "
            ),
          ]),
          _vm.contentpiece.ordered
            ? _c("div", { staticClass: "jiraStatus" })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "cp-open",
              on: {
                click: function ($event) {
                  return _vm.selectContentPiece()
                },
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "chevron-right"] },
              }),
            ],
            1
          ),
          !_vm.contentpiece.ordered
            ? _c(
                "div",
                {
                  staticClass: "cp-delete text-danger btn",
                  on: {
                    click: function ($event) {
                      return _vm.deleteContentPiece()
                    },
                  },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "trash"] },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }