var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container position-relative" },
    [
      ["ContentPiecesList"].includes(_vm.$route.name)
        ? _c("add-content-piece-button")
        : _vm._e(),
      _c(
        "search-and-pagination",
        {
          attrs: { vuexModuleName: "ContentPiece" },
          on: { loading: _vm.setLoading },
        },
        [
          _vm.resultLoading ? _c("loading") : _vm._e(),
          _c(
            "transition-group",
            { attrs: { name: "fade" } },
            _vm._l(_vm.contentPieces, function (contentPiece, index) {
              return _c("content-piece-single-line", {
                key: index,
                style: _vm.calculatedDelay(index),
                attrs: { contentpiece: contentPiece },
              })
            }),
            1
          ),
          !_vm.resultLoading && _vm.contentPieces.length === 0
            ? _c("p", { staticClass: "text-center text-bold mt-5" }, [
                _vm._v("- Keine Einträge gefunden -"),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }