var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container pb-8" }, [
      _vm._m(0),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card w-100" }, [
            _c(
              "div",
              { staticClass: "card-header" },
              [
                _c("strong", [_vm._v("Briefing Zusammenfassung")]),
                _c("print-jira", { attrs: { briefing: _vm.briefing } }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "card-body py-2" },
              [
                _c("print-base-info", { attrs: { briefing: _vm.briefing } }),
                _vm.briefing.product
                  ? _c("print-product", {
                      attrs: { product: _vm.briefing.product },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
        _vm.briefing.uploads && _vm.briefing.uploads.length > 0
          ? _c(
              "div",
              { staticClass: "col-4 mt-4" },
              [
                _c("print-uploads", {
                  attrs: { uploads: _vm.briefing.uploads },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.briefing.camera && _vm.briefing.camera.length > 0
          ? _c(
              "div",
              { staticClass: "col-4 mt-4" },
              [
                _c("print-camera-variants", {
                  attrs: { cameras: _vm.briefing.camera },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.briefing.product_bundle &&
        _vm.briefing.product_bundle.products.length > 0
          ? _c(
              "div",
              { staticClass: "col-4 mt-4" },
              [
                _c("print-product-bundle", {
                  attrs: { "product-bundle": _vm.briefing.product_bundle },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.briefing.briefingLowPoly
          ? _c("div", { staticClass: "col-12 mt-4" }, [
              _c("div", { staticClass: "card w-100" }, [
                _c(
                  "div",
                  { staticClass: "card-header" },
                  [
                    _c("strong", [_vm._v("Briefing Low Poly")]),
                    _c("print-jira", { attrs: { briefing: _vm.briefing } }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "card-body mb-2" },
                  [
                    _c("briefing-lowpoly-single", {
                      attrs: { briefing: _vm.briefing },
                    }),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
        _vm.briefing.camAssets && _vm.briefing.camAssets.length > 0
          ? _c(
              "div",
              { staticClass: "col-12 mt-4" },
              [
                _c("h3", [_vm._v("Cam Assets")]),
                _vm._l(_vm.briefing.camAssets, function (camAsset, index) {
                  return _c("div", { key: index }, [
                    _c(
                      "a",
                      { attrs: { href: camAsset.url, target: "_blank" } },
                      [_vm._v(_vm._s(camAsset.url))]
                    ),
                  ])
                }),
              ],
              2
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h2", [_vm._v("Zusammenfassung aktueller Stand")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }