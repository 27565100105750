var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.resultLoading ? _c("loading") : _vm._e(),
      _c("transition", { attrs: { name: "fadein" } }, [
        !_vm.resultLoading
          ? _c(
              "div",
              [
                !_vm.briefingArticle.ordered && !_vm.resultLoading
                  ? _c("briefing-article-details-view", {
                      attrs: {
                        id: _vm.id,
                        "briefing-article": _vm.briefingArticle,
                      },
                    })
                  : !_vm.resultLoading
                  ? _c("briefing-solo-details-print", {
                      attrs: {
                        id: _vm.id,
                        briefing: _vm.briefingArticle,
                        "link-back-name": "BriefingArticleList",
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "transition",
        { attrs: { name: "fadein" } },
        [
          !_vm.resultLoading
            ? _c("publish-briefing-solo-bar", {
                attrs: {
                  "briefing-id": _vm.briefingArticle.id,
                  ordered: _vm.briefingArticle.ordered,
                  "briefing-type": _vm.briefingArticle.briefingType,
                  "briefing-result-name": "Artikelbild Rendering(s)",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }