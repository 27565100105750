var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "card w-100" }, [
        _vm._m(0),
        _c("div", { staticClass: "card-body py-2" }, [
          _vm.cameras.length > 0
            ? _c(
                "div",
                { staticClass: "row" },
                _vm._l(
                  _vm.camerasToVariants(_vm.cameras),
                  function (variant, variantIndex) {
                    return _c("div", { key: "variant" + variantIndex }, [
                      _c("p", [
                        _c("b", [_vm._v("Variante " + _vm._s(variant.id))]),
                      ]),
                      _c("div", {
                        staticClass: "mb-3",
                        domProps: { innerHTML: _vm._s(variant.text) },
                      }),
                      _c(
                        "ul",
                        _vm._l(variant.cameras, function (camera, cameraIndex) {
                          return _c("li", { key: "camera" + cameraIndex }, [
                            _vm._v(
                              " Kamera " +
                                _vm._s(camera.id) +
                                " - " +
                                _vm._s(camera.text) +
                                " "
                            ),
                          ])
                        }),
                        0
                      ),
                    ])
                  }
                ),
                0
              )
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("strong", [_vm._v("Kameras und Varianten")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }