var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-12" }, [
        _vm.teams.length > 0
          ? _c("div", { staticClass: "pb-2" }, [
              _c("div", { staticClass: "editable" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("i18n.headline_teams")))]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedTeam,
                        expression: "selectedTeam",
                      },
                    ],
                    staticClass: "form-control",
                    class: _vm.getValidationClass("team"),
                    attrs: { id: "lowpoly-briefing-team" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedTeam = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "", disabled: "" } }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("i18n.select_placeholder")) + " "
                      ),
                    ]),
                    _vm._l(_vm.teams, function (team, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: team.name } },
                        [_vm._v(" " + _vm._s(team.name) + " ")]
                      )
                    }),
                  ],
                  2
                ),
                _c("div", {
                  staticClass: "invalid-feedback",
                  domProps: { innerHTML: _vm._s(_vm.getErrorMsg("team")) },
                }),
              ]),
            ])
          : _c("div", { staticClass: "pb-2" }, [_vm._v(" No team available ")]),
      ]),
    ]),
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          { staticClass: "editable" },
          [
            _c("datepicker", {
              attrs: {
                errors: _vm.errors,
                date: _vm.form.deadline,
                settings: _vm.datepickerSettings,
              },
              on: { updateEvent: _vm.deadlineUpdated },
            }),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "button",
      {
        staticClass: "btn btn-default float-right",
        attrs: { disabled: _vm.submitInProgress },
        on: {
          click: function ($event) {
            return _vm.save()
          },
        },
      },
      [_vm._v(" " + _vm._s(_vm.$t("i18n.btn_save")) + " ")]
    ),
    _c("div", { staticClass: "clearfix" }),
    _vm.briefingNotExportableError
      ? _c("div", { staticClass: "alert alert-danger" }, [
          _vm._v(" " + _vm._s(_vm.briefingNotExportableError) + " "),
        ])
      : _vm._e(),
    _c("div", { staticClass: "clearfix" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }