var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container", attrs: { id: "cp-details" } }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "container", attrs: { id: "br-details" } },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "mb-4" }, [
                    _vm.briefingRendering.hasContentPiece
                      ? _c("h1", { staticClass: "mb-4" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("i18n.headline_briefing")) +
                              " Szene " +
                              _vm._s(_vm.briefingRendering.scene) +
                              " "
                          ),
                        ])
                      : _c("h1", { staticClass: "mb-4" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("i18n.headline_briefing")) +
                              " - " +
                              _vm._s(_vm.briefingRendering.name) +
                              " "
                          ),
                        ]),
                  ]),
                ]),
              ]),
              !_vm.briefingRendering.hasContentPiece
                ? _c("div", { staticClass: "mb-4" }, [
                    _c("div", { staticClass: "editable" }, [
                      _c("label", [_vm._v(_vm._s(_vm.$t("i18n.label_name")))]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.name,
                            expression: "form.name",
                          },
                        ],
                        staticClass: "form-control w-100",
                        class: _vm.getValidationClass("name"),
                        attrs: {
                          id: "cp-title",
                          type: "text",
                          maxlength: "80",
                        },
                        domProps: { value: _vm.form.name },
                        on: {
                          change: _vm.update,
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "name", $event.target.value)
                          },
                        },
                      }),
                      _c("div", { staticClass: "form-text text-muted small" }, [
                        _vm._v(" " + _vm._s(_vm.$t("i18n.helper_name")) + " "),
                      ]),
                      _c("div", {
                        staticClass: "invalid-feedback",
                        domProps: {
                          innerHTML: _vm._s(_vm.getErrorMsg("name")),
                        },
                      }),
                    ]),
                  ])
                : _vm._e(),
              !_vm.briefingRendering.hasContentPiece
                ? _c("div", { staticClass: "mb-4" }, [
                    _c("div", { staticClass: "editable" }, [
                      _c("label", [_vm._v(_vm._s(_vm.$t("i18n.label_topic")))]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.topic,
                            expression: "form.topic",
                          },
                        ],
                        staticClass: "form-control w-100",
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.topic },
                        on: {
                          change: _vm.update,
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "topic", $event.target.value)
                          },
                        },
                      }),
                      _c("div", { staticClass: "form-text text-muted small" }, [
                        _vm._v(" " + _vm._s(_vm.$t("i18n.helper_topic")) + " "),
                      ]),
                      _c("div", {
                        staticClass: "invalid-feedback",
                        domProps: {
                          innerHTML: _vm._s(_vm.getErrorMsg("topic")),
                        },
                      }),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "row mb-6" }, [
                _c("div", { staticClass: "col-4" }, [
                  !_vm.briefingRendering.hasContentPiece
                    ? _c("div", { staticClass: "pb-2" }, [
                        _c("div", [
                          _c("label", [
                            _vm._v(_vm._s(_vm.$t("i18n.label_team"))),
                          ]),
                          _c(
                            "select",
                            {
                              staticClass: "form-control readonly",
                              attrs: { disabled: "" },
                            },
                            [
                              _c("option", [
                                _vm._v(_vm._s(_vm.briefingRendering.team)),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "form-text text-muted small" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("i18n.helper_team")) + " "
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  { staticClass: "col-4" },
                  [
                    _c("briefing-deadline", {
                      attrs: {
                        "deadline-prop": _vm.form.deadline,
                        "datepicker-settings": _vm.datepickerSettings,
                      },
                      on: { deadlineUpdated: _vm.deadlineUpdated },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "editable" }, [
                    _c("label", [_vm._v(_vm._s(_vm.$t("i18n.headline_room")))]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.room,
                            expression: "form.room",
                          },
                        ],
                        staticClass: "form-control",
                        class: _vm.getValidationClass("room"),
                        attrs: { id: "cp-room" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.form,
                                "room",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              return _vm.roomUpdated($event)
                            },
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { disabled: "", value: "" } }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("i18n.select_placeholder")) +
                              " "
                          ),
                        ]),
                        _vm._l(_vm.roomTypes, function (room) {
                          return _c(
                            "option",
                            { key: room, domProps: { value: room } },
                            [_vm._v(" " + _vm._s(room) + " ")]
                          )
                        }),
                      ],
                      2
                    ),
                    _c("div", {
                      staticClass: "invalid-feedback",
                      domProps: { innerHTML: _vm._s(_vm.getErrorMsg("room")) },
                    }),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "row mb-2" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("h2", [
                    _vm._v(_vm._s(_vm.$t("i18n.headline_global_parameters"))),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.$t("i18n.description_global_parameters"))
                    ),
                  ]),
                ]),
              ]),
              _c("image-formats-briefing-rendering", {
                attrs: { "image-format-values": _vm.briefingRendering.formats },
                on: { updateFormats: _vm.updateFormats },
              }),
              _c("div", { staticClass: "row mb-7" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("i18n.label_description"))),
                  ]),
                ]),
                _c("div", { staticClass: "col-12 mb-6" }, [
                  _c(
                    "div",
                    { staticClass: "editable mb-4 h-100" },
                    [
                      _c("editor", {
                        attrs: {
                          "initial-content": _vm.form.description,
                          placeholder: _vm.$t("i18n.placeholder_description"),
                          "active-buttons": [
                            "bold",
                            "strike",
                            "underline",
                            "bullet_list",
                            "ordered_list",
                          ],
                        },
                        on: { blur: _vm.editorUpdated },
                      }),
                      _c("span", { staticClass: "text-muted small" }, [
                        _c("p", [
                          _vm._v(_vm._s(_vm.$t("i18n.helper_description"))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "col-12 col-md-4" },
                  [
                    _c("products-bundle", {
                      attrs: {
                        "product-bundle": _vm.briefingRendering.product_bundle,
                        "parent-i-d": _vm.id,
                        "model-to-sync-with": "BriefingRendering",
                        settings: _vm.productBundleSettings,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12 col-md-4 references" },
                  [
                    _c("items-list", {
                      attrs: {
                        "items-prop": _vm.briefingRendering.references,
                        settings: _vm.referenceSettings,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12 col-md-4" },
                  [
                    _c("h3", [
                      _vm._v(_vm._s(_vm.$t("i18n.headline_references_upload"))),
                    ]),
                    _c("span", { staticClass: "text-muted small" }, [
                      _c("p", [
                        _vm._v(_vm._s(_vm.$t("i18n.helper_references_upload"))),
                      ]),
                    ]),
                    _c("upload", {
                      attrs: {
                        id: "upload-briefing-rendering",
                        "upload-path": "briefing-rendering",
                        "already-uploaded-files": _vm.briefingRendering.uploads,
                        "uploadable-id": _vm.id,
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-12 mt-5" }, [
                  _c("h2", [
                    _vm._v(_vm._s(_vm.$t("i18n.cameras_variants_headline"))),
                  ]),
                  _c("p", { staticClass: "mb-5" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("i18n.cameras_variants_desc")) + " "
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("cameras-and-variants", {
                      attrs: { "cameras-and-variants": _vm.form.camera },
                      on: {
                        updateCamerasAndVariants: _vm.updateCamerasAndVariants,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }