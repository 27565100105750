var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-4 d-flex align-items-stretch" }, [
    _c("div", { staticClass: "card w-100" }, [
      _c("div", { staticClass: "card-header" }, [
        _c("strong", [_vm._v("Szene " + _vm._s(_vm.briefing.scene))]),
      ]),
      _c("div", { staticClass: "card-body py-2 even-odd-row" }, [
        _vm._m(0),
        _c("div", { staticClass: "row" }, [
          _vm._m(1),
          _c("div", { staticClass: "col-6" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm._f("moment")(
                    _vm.briefing.deadline
                      ? _vm.briefing.deadline
                      : _vm.contentPiece.deadline,
                    "DD.MM.Y"
                  )
                ) +
                " "
            ),
          ]),
        ]),
        _vm.briefing.camera != null
          ? _c(
              "div",
              [
                _vm._l(_vm.briefing.camera, function (camera, cameraIndex) {
                  return _c(
                    "div",
                    { key: "camera" + cameraIndex, staticClass: "row" },
                    [
                      _c("div", { staticClass: "col-6" }, [
                        _c("strong", [_vm._v("Kamera " + _vm._s(camera.id))]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        _vm._l(
                          camera.variants,
                          function (variant, variantIndex) {
                            return _c(
                              "span",
                              { key: "variant" + variantIndex },
                              [_vm._v(" V" + _vm._s(variant.id) + ", ")]
                            )
                          }
                        ),
                        0
                      ),
                    ]
                  )
                }),
                _c("div", { staticClass: "row" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "col-6" }, [
                    _vm._v(" " + _vm._s(_vm.resultedImages) + " "),
                  ]),
                ]),
              ],
              2
            )
          : _vm._e(),
        _vm.briefing.product_bundle != null
          ? _c(
              "div",
              { staticClass: "row" },
              [
                _vm._m(3),
                _vm._l(
                  _vm.briefing.product_bundle.products,
                  function (briefingProduct, briefingProductIndex) {
                    return _c(
                      "div",
                      { key: briefingProductIndex, staticClass: "col-12" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("getIsoSku")(briefingProduct)) +
                            " "
                        ),
                      ]
                    )
                  }
                ),
              ],
              2
            )
          : _vm._e(),
        _vm.briefing.uploads.length > 0
          ? _c("div", { staticClass: "row" }, [
              _vm._m(4),
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(
                    _vm.briefing.uploads,
                    function (image, briefingUploadIndex) {
                      return _c(
                        "div",
                        { key: briefingUploadIndex, staticClass: "col-4" },
                        [
                          _c("img", {
                            staticClass: "border img-fluid mb-4",
                            attrs: {
                              src: _vm.getThumbnailPath(image.hashed_name),
                              alt: image.hashed_name,
                            },
                            on: { error: _vm.replaceByDefaultThumb },
                          }),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ]),
            ])
          : _vm._e(),
        _vm.briefing.camAssets && _vm.briefing.camAssets.length > 0
          ? _c(
              "div",
              { staticClass: "col-12 mt-4" },
              [
                _c("h3", [_vm._v("Cam Assets")]),
                _vm._l(_vm.briefing.camAssets, function (camAsset, index) {
                  return _c("div", { key: index }, [
                    _c(
                      "a",
                      { attrs: { href: camAsset.url, target: "_blank" } },
                      [_vm._v(_vm._s(camAsset.url))]
                    ),
                  ])
                }),
              ],
              2
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [_c("strong", [_vm._v("Typ")])]),
      _c("div", { staticClass: "col-6" }, [_vm._v(" Freies Briefing ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6" }, [
      _c("strong", [_vm._v("Deadline")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6" }, [
      _c("strong", [_vm._v("Bilder gesamt")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6" }, [
      _c("strong", [_vm._v("Zusätzliche Artikel")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6" }, [
      _c("strong", [_vm._v("Referenzen")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }