var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "b-modal",
              rawName: "v-b-modal",
              value: "new-briefing",
              expression: "'new-briefing'",
            },
          ],
          staticClass: "float-right btn btn-outline-primary ml-2 mb-4 mb-xl-0",
        },
        [
          _c("font-awesome-icon", { attrs: { icon: ["fas", "plus"] } }),
          _vm._v(" Neu "),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "new-briefing-modal",
          attrs: {
            id: "new-briefing",
            "no-close-on-backdrop": "",
            "hide-footer": "",
            centered: "",
            title: _vm.$t("i18n.headline_create_briefing", { type: "Eintrag" }),
          },
        },
        [
          _c(_vm.briefingType, {
            tag: "component",
            on: {
              hideModal: function ($event) {
                return _vm.hideModal()
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }