var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-100 d-flex flex-column" }, [
    _c("div", [
      _c("h3", [_vm._v(_vm._s(_vm.settings.headlinePlaceholder))]),
      _c("span", {
        staticClass: "text-muted small",
        domProps: { innerHTML: _vm._s(_vm.settings.description) },
      }),
    ]),
    _c("div", [
      _c(
        "div",
        { staticClass: "product-bundle" },
        [
          _vm.items.length === 0
            ? _c("div", { staticClass: "text-muted small mb-4" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("info_no_articles")))]),
              ])
            : _c(
                "div",
                [
                  _c("div", { staticClass: "row mb-3" }, [
                    _c("div", { staticClass: "col-3 text-center" }, [
                      _c("strong", [_vm._v(_vm._s(_vm.$t("th_image")))]),
                    ]),
                    _c("div", { staticClass: "col-3" }, [
                      _c("strong", [_vm._v(_vm._s(_vm.$t("th_sku")))]),
                    ]),
                  ]),
                  _vm._l(_vm.items, function (article, index) {
                    return _c(
                      "div",
                      {
                        key: article.articleNumber,
                        staticClass: "row listitem",
                      },
                      [
                        _c("div", { staticClass: "col-3 text-center" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal",
                                  value:
                                    "" +
                                    _vm.settings.productBundleType +
                                    article.articleNumber +
                                    "",
                                  expression:
                                    "'' + settings.productBundleType + article.articleNumber + ''",
                                },
                              ],
                              staticClass: "article-img-wrapper",
                            },
                            [
                              _c("img", {
                                staticClass: "img-fluid",
                                attrs: {
                                  src: _vm.imgUrl(
                                    article.sdbProduct.articleNumber
                                  ),
                                },
                                on: { error: _vm.imgPlaceholder },
                              }),
                              _c(
                                "b-modal",
                                {
                                  attrs: {
                                    id:
                                      _vm.settings.productBundleType +
                                      article.sdbProduct.articleNumber,
                                    size: "lg",
                                    centered: "",
                                    "hide-footer": "",
                                    title:
                                      article.sdbProduct
                                        .nationalArticleIdInclCheckDigit,
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "img-fluid",
                                    attrs: {
                                      src: _vm.imgUrl(
                                        article.sdbProduct.articleNumber
                                      ),
                                    },
                                    on: { error: _vm.imgPlaceholder },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "col-9" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "p",
                              {
                                staticClass: "form-control transparent",
                                attrs: { readonly: "" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      article.sdbProduct.country.toLowerCase() +
                                        "-" +
                                        article.sdbProduct
                                          .articleNumberInclCheckDigit
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              ),
          _vm.editable
            ? _c(
                "div",
                {
                  staticClass:
                    "btn btn-block text-white btn-primary cursor-pointer edit-productbundle",
                  on: {
                    click: function ($event) {
                      return _vm.showModal()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("btn_edit_articles")) + " ")]
              )
            : _vm._e(),
          _c(
            "b-modal",
            {
              attrs: {
                id: "assign-articles",
                size: "xl",
                centered: "",
                "no-close-on-backdrop": true,
                "no-close-on-esc": true,
              },
              scopedSlots: _vm._u([
                {
                  key: "modal-header",
                  fn: function () {
                    return [
                      _c("h5", [
                        _vm._v(_vm._s(_vm.$t("headline_select_articles"))),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "modal-footer",
                  fn: function ({ ok }) {
                    return [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "primary text-white" },
                          on: {
                            click: function ($event) {
                              return _vm.closeModal()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("btn_ok")) + " ")]
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.modalShow,
                callback: function ($$v) {
                  _vm.modalShow = $$v
                },
                expression: "modalShow",
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-8" },
                  [
                    _c("iass-wrapper", {
                      on: { articleAdded: _vm.addProductFromSearch },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-4" },
                  [
                    _c("h3", [
                      _vm._v(_vm._s(_vm.settings.headlinePlaceholder)),
                    ]),
                    _vm.errors
                      ? _c(
                          "div",
                          { staticClass: "row mb-3" },
                          _vm._l(_vm.errors, function (error, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "alert alert-danger" },
                              [_vm._v(" " + _vm._s(error[0]) + " ")]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "row mb-3" }, [
                      _c("div", { staticClass: "col-3" }, [
                        _c("strong", [_vm._v(_vm._s(_vm.$t("th_image")))]),
                      ]),
                      _c("div", { staticClass: "col-3" }, [
                        _c("strong", [_vm._v(_vm._s(_vm.$t("th_sku")))]),
                      ]),
                    ]),
                    _vm._l(_vm.items, function (article, index) {
                      return _c(
                        "div",
                        {
                          key: article.articleNumber,
                          staticClass: "row listitem",
                        },
                        [
                          _c("div", { staticClass: "col-3" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "b-modal",
                                    rawName: "v-b-modal",
                                    value:
                                      "" +
                                      _vm.settings.productBundleType +
                                      article.sdbProduct.articleNumber +
                                      "",
                                    expression:
                                      "\n                    '' + settings.productBundleType + article.sdbProduct.articleNumber + ''\n                  ",
                                  },
                                ],
                                staticClass: "article-img-wrapper d-flex",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "justify-content-center align-self-center",
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "img-fluid border",
                                      attrs: {
                                        src: _vm.imgUrl(
                                          article.sdbProduct.articleNumber
                                        ),
                                      },
                                      on: { error: _vm.imgPlaceholder },
                                    }),
                                    _c(
                                      "b-modal",
                                      {
                                        attrs: {
                                          id:
                                            "inmodal" +
                                            _vm.settings.productBundleType +
                                            article.sdbProduct.articleNumber,
                                          size: "lg",
                                          centered: "",
                                          "hide-footer": "",
                                          title:
                                            article.sdbProduct
                                              .nationalArticleIdInclCheckDigit,
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "img-fluid",
                                          attrs: {
                                            src: _vm.imgUrl(
                                              article.sdbProduct.articleNumber
                                            ),
                                          },
                                          on: { error: _vm.imgPlaceholder },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "col-4" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "p",
                                {
                                  staticClass: "form-control transparent",
                                  attrs: { readonly: "" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        article.sdbProduct.country.toLowerCase() +
                                          "-" +
                                          article.sdbProduct
                                            .articleNumberInclCheckDigit
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-2" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn text-danger delete-article",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteProduct(
                                        article.id,
                                        index
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["fas", "trash"] },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }