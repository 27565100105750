var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "row shadow bg-white mb-4 cp mx-1 mx-sm-0",
        attrs: { id: _vm.briefing.id },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "col-12 col-sm-3 bg-green text-white p-2 cp-info small",
            class: [_vm.briefing.ordered ? "bg-success" : "bg-primary"],
          },
          [
            _c(
              "span",
              {
                staticClass: "d-inline d-sm-block mb-2 small font-weight-bold",
              },
              [
                _vm._v(
                  _vm._s(_vm._f("moment")(_vm.briefing.created_at, "DD.MM.Y"))
                ),
              ]
            ),
            _c(
              "span",
              { staticClass: "d-inline d-sm-block text-truncate w-100" },
              [_vm._v(_vm._s(_vm.briefing.user.name))]
            ),
            _c(
              "span",
              { staticClass: "d-inline d-sm-block text-truncate w-100" },
              [_c("i", [_vm._v(_vm._s(_vm.briefing.team))])]
            ),
            _vm.briefing.briefingType === "BriefingArticle" &&
            _vm.briefing.projectInfo !== null
              ? _c(
                  "div",
                  [
                    _c("hr", { staticClass: "mt-3 mb-3" }),
                    _c(
                      "router-link",
                      {
                        staticClass: "text-white link",
                        attrs: {
                          to: {
                            name: "ProjectDetails",
                            params: {
                              id: _vm.briefing.projectInfo.projectId,
                              blockid:
                                _vm.briefing.projectInfo.batchBriefingArticleId,
                            },
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "text-truncate w-100" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.briefing.projectInfo.projectName) +
                              " "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.briefing.ordered && _vm.briefing.jira_export
              ? _c("div", { staticClass: "jira-export small p-2" }, [
                  _c(
                    "a",
                    {
                      staticClass: "link text-white",
                      attrs: {
                        target: "_blank",
                        href: _vm.jiraURL(_vm.briefing),
                      },
                    },
                    [_vm._v(_vm._s(_vm.briefing.jira_export.response.key))]
                  ),
                  _vm._v(
                    _vm._s(
                      _vm.briefing.jira_export.status != null
                        ? " - " + _vm.briefing.jira_export.status.name
                        : ""
                    ) + " "
                  ),
                ])
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "col-12 col-sm-2 text-center d-flex justify-content-center",
          },
          [
            _vm.briefing.product
              ? _c("img", {
                  staticClass: "img-fluid product-img align-self-center",
                  attrs: {
                    src: _vm.productImageURL(
                      _vm.briefing.product.sdbProduct.articleNumber
                    ),
                  },
                  on: { error: _vm.fallbackPlaceholder },
                })
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-12 col-sm-7 py-3 description" },
          [
            _c(
              "h4",
              {
                staticClass: "cursor-pointer d-inline",
                on: {
                  click: function ($event) {
                    return _vm.detailView()
                  },
                },
              },
              [
                _vm.briefing.listTitle
                  ? _c("div", [
                      _vm._v(" " + _vm._s(_vm.briefing.listTitle) + " "),
                    ])
                  : _c("div", [_vm._v(" - ")]),
              ]
            ),
            _vm.briefing.product
              ? _c("div", { staticClass: "cp-desc mt-3" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.briefing.product.sdbProduct
                          .nationalArticleIdInclCheckDigit
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
            _vm.briefing.briefingType === "BriefingArticle"
              ? _c("briefing-lowpoly-single", {
                  attrs: { briefing: _vm.briefing },
                  on: {
                    openLowpolyModal: function ($event) {
                      return _vm.openLowpolyModal()
                    },
                  },
                })
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "cp-open",
                on: {
                  click: function ($event) {
                    return _vm.detailView()
                  },
                },
              },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fas", "chevron-right"] },
                }),
              ],
              1
            ),
            !_vm.briefing.ordered
              ? _c(
                  "div",
                  {
                    staticClass: "cp-delete text-danger btn",
                    on: {
                      click: function ($event) {
                        return _vm.deleteBriefing()
                      },
                    },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fas", "trash"] },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.briefing.briefingType === "BriefingRendering"
              ? _c(
                  "div",
                  {
                    staticClass: "cp-clone btn",
                    on: {
                      click: function ($event) {
                        return _vm.cloneBriefing()
                      },
                    },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fas", "copy"] },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }