var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container position-relative pb-4" },
    [
      _c("add-briefing-button", {
        attrs: { "briefing-type": _vm.briefingType },
      }),
      _c(
        "search-and-pagination",
        {
          attrs: { vuexModuleName: _vm.briefingType },
          on: { loading: _vm.setLoading },
        },
        [
          _vm.resultLoading ? _c("loading") : _vm._e(),
          !_vm.resultLoading
            ? _c(
                "transition-group",
                { attrs: { name: "fade" } },
                _vm._l(_vm.briefings, function (briefing, index) {
                  return _c("briefing-single-line", {
                    key: index,
                    style: _vm.calculatedDelay(index),
                    attrs: { briefing: briefing },
                    on: { openLowpolyModal: _vm.openLowpolyModal },
                  })
                }),
                1
              )
            : _vm._e(),
          !_vm.resultLoading && _vm.briefings.length === 0
            ? _c("p", { staticClass: "text-center text-bold mt-5" }, [
                _vm._v("- Keine Einträge gefunden -"),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("new-briefing-lowpoly-modal", {
        attrs: { "briefing-article-id": _vm.briefingArticleId },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }