var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.viewStyle == "List"
      ? _c(
          "ul",
          { staticClass: "uploaded list-group" },
          _vm._l(_vm.files, function (file, index) {
            return _c(
              "li",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover.right",
                    value: file.hashed_name,
                    expression: "file.hashed_name",
                    modifiers: { hover: true, right: true },
                  },
                ],
                key: index,
                staticClass:
                  "list-group-item listitem d-flex justify-content-between align-items-center",
              },
              [
                _c("img", {
                  staticClass: "border mr-1",
                  staticStyle: { height: "50px", width: "auto" },
                  attrs: { src: _vm.getThumbnailPath(file.hashed_name) },
                  on: { error: _vm.replaceByDefaultThumb },
                }),
                _c("span", [_vm._v(_vm._s(file.file_name))]),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm.viewStyle == "Grid"
      ? _c(
          "div",
          { staticClass: "row upload-grid" },
          _vm._l(_vm.files, function (file, index) {
            return _c("div", { key: index, staticClass: "col-2" }, [
              _c("img", {
                staticClass: "border img-fluid",
                staticStyle: { width: "170px", height: "auto" },
                attrs: {
                  src: _vm.getThumbnailPath(file.hashed_name),
                  alt: file.hashed_name,
                },
                on: { error: _vm.replaceByDefaultThumb },
              }),
              _c("p", { staticClass: "small text-break text-muted mt-2" }, [
                _vm._v(" " + _vm._s(file.file_name) + " "),
              ]),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }