var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "text-white",
      class: _vm.setBackground(),
      attrs: { id: "briefing-navigation" },
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row py-3" }, [
          !_vm.ordered
            ? _c("div", { staticClass: "col-12 text-center" }, [
                _vm.error
                  ? _c("div", [
                      _c("p", [
                        _c("b", [
                          _vm._v("Briefing kann (noch) nicht bestellt werden:"),
                        ]),
                        _c("br"),
                        _vm._v(" " + _vm._s(_vm.translatedError) + " "),
                      ]),
                      _c("hr"),
                    ])
                  : _vm._e(),
                _c("p", { staticClass: "m-0" }, [
                  _vm._v(" " + _vm._s(_vm.$t("text_done")) + " "),
                  _c(
                    "button",
                    {
                      staticClass: "mx-2 btn btn-dark text-white btn-sm",
                      class: { disabled: !_vm.isSelectedExportable },
                      attrs: { disabled: !_vm.isSelectedExportable },
                      on: { click: _vm.exportBriefing },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("btn_send", { type: _vm.briefingResultName })
                          ) +
                          " "
                      ),
                      _c("font-awesome-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: ["fas", "paper-plane"] },
                      }),
                    ],
                    1
                  ),
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("text_continue_process")),
                    },
                  }),
                ]),
              ])
            : _vm.ordered
            ? _c("div", { staticClass: "col-12 text-center" }, [
                _c("p", { staticClass: "m-0" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("text_ordered", { type: _vm.briefingResultName })
                      ) +
                      " "
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }