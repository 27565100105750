var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "shadow p-3 bg-white mb-4 cp mx-1 mx-sm-0" },
    [
      _c("div", { staticClass: "d-block small mb-2" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("created_at_updated_at", {
                created: _vm.createdAtFormatted,
                updated: _vm.updatedAtFormatted,
              })
            ) +
            " "
        ),
      ]),
      _c(
        "h5",
        {
          staticClass: "link d-block text-body",
          on: {
            click: function ($event) {
              return _vm.detailView()
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.project.name) + " ")]
      ),
      _c("div", { domProps: { innerHTML: _vm._s(_vm.project.description) } }),
      _c("hr"),
      _c("div", { staticClass: "d-flex small" }, [
        _c("div", [
          _vm._v("Deadline: "),
          _vm.project.deadline
            ? _c("span", [
                _vm._v(
                  _vm._s(_vm._f("moment")(_vm.project.deadline, "DD.MM.YYYY"))
                ),
              ])
            : _c("span", [_vm._v("-")]),
        ]),
        _c("div", { staticClass: "ml-auto mr-5" }, [
          _vm._v(" Führendes Team: " + _vm._s(_vm.project.team) + " "),
        ]),
        _c(
          "div",
          {
            staticClass: "detailView",
            on: {
              click: function ($event) {
                return _vm.detailView()
              },
            },
          },
          [
            _c("font-awesome-icon", {
              attrs: { icon: ["fas", "chevron-right"] },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }