var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-12 col-md-6" }, [
    _vm.favorite
      ? _c("img", {
          staticClass: "w-100 img-fluid pl-3 mt-0 mt-md-9",
          attrs: { src: _vm.favorite.url, alt: _vm.favorite.baseName },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }