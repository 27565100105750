var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "card w-100" }, [
        _c("div", { staticClass: "card-header" }, [
          _c("strong", [_vm._v(_vm._s(_vm.headline))]),
        ]),
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _vm._m(0),
            _vm._l(_vm.productBundle.products, function (product, index) {
              return _c("div", { key: index, staticClass: "row" }, [
                _c("div", { staticClass: "col-4" }, [
                  _c("img", {
                    staticClass: "border img-fluid mb-2",
                    attrs: {
                      src: product.sdb_product.imagelink,
                      width: "150",
                      alt: "",
                    },
                    on: { error: _vm.fallbackPlaceholder },
                  }),
                ]),
                _c("div", { staticClass: "col-4" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        product.sdb_product.nationalArticleIdInclCheckDigit
                      ) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "col-4" }, [
                  _vm._v(" " + _vm._s(product.qty) + " "),
                ]),
              ])
            }),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col-4" }, [_vm._v(" Bild ")]),
      _c("div", { staticClass: "col-4" }, [_vm._v(" Nummer ")]),
      _c("div", { staticClass: "col-4" }, [_vm._v(" Menge ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }