var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.resultLoading ? _c("loading") : _vm._e(),
      _c("transition", { attrs: { name: "fadein" } }, [
        !_vm.resultLoading
          ? _c("div", [
              _vm.briefingShowFlat.send == 0
                ? _c("div", [
                    _c("div", { staticClass: "container" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          {
                            staticClass: "container",
                            attrs: { id: "bsf-details" },
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c("div", { staticClass: "mb-4" }, [
                                  _c("h1", { staticClass: "mb-4" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("i18n.headline_briefing")
                                        ) +
                                        " Szene " +
                                        _vm._s(_vm.briefingShowFlat.scene) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12 col-md-6" }, [
                                _c("div", { staticClass: "mb-4" }, [
                                  _c(
                                    "div",
                                    { staticClass: "editable" },
                                    [
                                      _c("datepicker", {
                                        attrs: {
                                          errors: _vm.errors,
                                          date: _vm.form.deadline,
                                          settings: _vm.datepickerSettings,
                                        },
                                        on: {
                                          updateEvent: _vm.deadlineUpdated,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "row mb-4" },
                              [
                                _c("favorite-selector", {
                                  on: { favoriteSelected: _vm.updateFavorite },
                                }),
                                _c("favorite-selected-image", {
                                  attrs: { favorite: _vm.form.favorite },
                                }),
                              ],
                              1
                            ),
                            _vm.getErrorMsg("show_flat_id")
                              ? _c(
                                  "div",
                                  { staticClass: "alert alert-danger" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("i18n.error_show_flat")) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c("div", { staticClass: "col-12" }, [
                              _c("h2", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("i18n.cameras_variants_headline")
                                  )
                                ),
                              ]),
                              _c("p", { staticClass: "mb-5" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("i18n.cameras_variants_desc")
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-12 mt-5 mb-5" },
                              [
                                _vm.form.favorite
                                  ? _c("cameras-and-variants", {
                                      attrs: {
                                        "cameras-and-variants": _vm.form.camera,
                                        "disabled-camera": true,
                                        "standard-camera-name":
                                          _vm.form.favorite.baseName,
                                      },
                                      on: {
                                        updateCamerasAndVariants:
                                          _vm.updateCamerasAndVariants,
                                      },
                                    })
                                  : _c(
                                      "div",
                                      { staticClass: "alert alert-danger" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "i18n.choose_reference_image"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "row mb-7" }, [
                              _c(
                                "div",
                                { staticClass: "col-12 col-md-4" },
                                [
                                  _c("products-bundle", {
                                    attrs: {
                                      "product-bundle":
                                        _vm.briefingShowFlat.product_bundle,
                                      "parent-i-d": _vm.id,
                                      "model-to-sync-with": "BriefingShowFlat",
                                      settings: _vm.productBundleSettings,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-12 col-md-4 references" },
                                [
                                  _c("items-list", {
                                    attrs: {
                                      "items-prop":
                                        _vm.briefingShowFlat.references,
                                      settings: _vm.referenceSettings,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-12 col-md-4" },
                                [
                                  _c("h3", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "i18n.headline_references_upload"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c(
                                    "span",
                                    { staticClass: "text-muted small" },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "i18n.helper_references_upload"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c("upload", {
                                    attrs: {
                                      id: "upload-briefing-rendering",
                                      "upload-path": "briefing-show-flat",
                                      "already-uploaded-files":
                                        _vm.briefingShowFlat.uploads,
                                      "uploadable-id": _vm.id,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "col-12 mt-5" }, [
                                _c("label", [
                                  _vm._v(
                                    _vm._s(_vm.$t("i18n.label_description"))
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "col-12 mb-5" }, [
                                _c(
                                  "div",
                                  { staticClass: "editable mb-4 h-100" },
                                  [
                                    _c("editor", {
                                      attrs: {
                                        "initial-content": _vm.form.description,
                                        placeholder: _vm.$t(
                                          "i18n.placeholder_description"
                                        ),
                                        "active-buttons": [
                                          "bold",
                                          "strike",
                                          "underline",
                                          "bullet_list",
                                          "ordered_list",
                                        ],
                                      },
                                      on: { blur: _vm.editorUpdated },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }