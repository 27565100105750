var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-100 d-flex flex-column" }, [
    _c("div", [
      _c("h3", [_vm._v(_vm._s(_vm.settings.headlinePlaceholder))]),
      _c("span", {
        staticClass: "text-muted small",
        domProps: { innerHTML: _vm._s(_vm.settings.description) },
      }),
    ]),
    _c(
      "div",
      {
        staticClass: "big-border px-3 py-3 productBundle",
        class: [
          { published: _vm.publishedStatus > 0 },
          "productBundle_type_" + _vm.settings.productBundleType,
        ],
      },
      [
        !_vm.productBundle
          ? _c("div", [
              _c(
                "span",
                {
                  staticClass:
                    "link form-control transparent create-product-bundle",
                  on: {
                    click: function ($event) {
                      return _vm.createProductsBundle()
                    },
                  },
                },
                [_vm._v("Create new product bundle +")]
              ),
            ])
          : _vm._e(),
        _vm.productBundle
          ? _c(
              "div",
              { staticClass: "product-bundle" },
              [
                _vm._m(0),
                _vm._l(_vm.items, function (article, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "row listitem" },
                    [
                      _c("div", { staticClass: "col-3" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-modal",
                                rawName: "v-b-modal",
                                value:
                                  "" +
                                  _vm.settings.productBundleType +
                                  article.sdb_product.articleNumber +
                                  "",
                                expression:
                                  "''+settings.productBundleType+article.sdb_product.articleNumber+''",
                              },
                            ],
                            staticClass: "article-img-wrapper d-flex",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "justify-content-center align-self-center",
                              },
                              [
                                _c("img", {
                                  staticClass: "img-fluid",
                                  attrs: {
                                    src: _vm.productImageURL(
                                      article.sdb_product.articleNumber
                                    ),
                                  },
                                  on: { error: _vm.fallbackPlaceholder },
                                }),
                                _c(
                                  "b-modal",
                                  {
                                    attrs: {
                                      id:
                                        _vm.settings.productBundleType +
                                        article.sdb_product.articleNumber,
                                      size: "lg",
                                      centered: "",
                                      "hide-footer": "",
                                      title: _vm._f("getIsoSku")(article),
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "img-fluid",
                                      attrs: {
                                        src: _vm.productImageURL(
                                          article.sdb_product.articleNumber
                                        ),
                                      },
                                      on: { error: _vm.fallbackPlaceholder },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "col-4" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "p",
                            {
                              staticClass: "form-control transparent",
                              attrs: { readonly: "" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm._f("getIsoSku")(article)) + " "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-2 p-0" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _vm.publishedStatus != 2
                            ? _c("div", { staticClass: "editable" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: article.qty,
                                      expression: "article.qty",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: { type: "number", min: "1" },
                                  domProps: { value: article.qty },
                                  on: {
                                    change: function ($event) {
                                      return _vm.updateArticleInProductBundle(
                                        article
                                      )
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        article,
                                        "qty",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ])
                            : _c("p", { staticClass: "form-control" }, [
                                _vm._v(" " + _vm._s(article.qty) + " "),
                              ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-3" }, [
                        _vm.publishedStatus != 2
                          ? _c("div", { staticClass: "form-group" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn text-danger delete-article",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteArticleFromProductBundle(
                                        article,
                                        index
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["fas", "trash"] },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  )
                }),
                _c(
                  "div",
                  {
                    staticClass:
                      "btn btn-block btn-sm text-white btn-primary cursor-pointer edit-productbundle",
                    on: {
                      click: function ($event) {
                        _vm.modalShow = !_vm.modalShow
                      },
                    },
                  },
                  [_vm._v(" Artikel bearbeiten ")]
                ),
                _c(
                  "b-modal",
                  {
                    attrs: {
                      size: "xl",
                      centered: "",
                      title: "Wähle deine " + _vm.settings.headlinePlaceholder,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "modal-footer",
                          fn: function ({ ok }) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "btn btn-primary text-white",
                                  on: {
                                    click: function ($event) {
                                      return ok()
                                    },
                                  },
                                },
                                [_vm._v(" OK ")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2899090159
                    ),
                    model: {
                      value: _vm.modalShow,
                      callback: function ($$v) {
                        _vm.modalShow = $$v
                      },
                      expression: "modalShow",
                    },
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-8" },
                        [
                          _c("h3", [
                            _vm._v(
                              "Alle " + _vm._s(_vm.settings.headlinePlaceholder)
                            ),
                          ]),
                          _c("iass-wrapper", {
                            on: { articleAdded: _vm.addProductFromSearch },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("h3", [
                            _vm._v(
                              "Ausgewählte " +
                                _vm._s(_vm.settings.headlinePlaceholder)
                            ),
                          ]),
                          _vm.errors
                            ? _c(
                                "div",
                                { staticClass: "row mb-3" },
                                _vm._l(_vm.errors, function (error, index) {
                                  return _c("div", { key: index }, [
                                    _vm._v(" " + _vm._s(error) + " "),
                                  ])
                                }),
                                0
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "row mb-3" }, [
                            _c("div", { staticClass: "col-3" }, [
                              _c("strong", [_vm._v("Image")]),
                            ]),
                            _c("div", { staticClass: "col-3" }, [
                              _c("strong", [_vm._v("Article")]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-4 p-0 text-center" },
                              [_c("strong", [_vm._v("Qty")])]
                            ),
                          ]),
                          _vm._l(_vm.items, function (article, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "row listitem" },
                              [
                                _c("div", { staticClass: "col-3" }, [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName: "v-b-modal",
                                          value:
                                            "" +
                                            _vm.settings.productBundleType +
                                            article.sdb_product.articleNumber +
                                            "",
                                          expression:
                                            "''+settings.productBundleType+article.sdb_product.articleNumber+''",
                                        },
                                      ],
                                      staticClass: "article-img-wrapper d-flex",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "justify-content-center align-self-center",
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "img-fluid",
                                            attrs: {
                                              src: _vm.productImageURL(
                                                article.sdb_product
                                                  .articleNumber
                                              ),
                                            },
                                            on: {
                                              error: _vm.fallbackPlaceholder,
                                            },
                                          }),
                                          _c(
                                            "b-modal",
                                            {
                                              attrs: {
                                                id:
                                                  "inmodal" +
                                                  _vm.settings
                                                    .productBundleType +
                                                  article.sdb_product
                                                    .articleNumber,
                                                size: "lg",
                                                centered: "",
                                                "hide-footer": "",
                                                title:
                                                  _vm._f("getIsoSku")(article),
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "img-fluid",
                                                attrs: {
                                                  src: _vm.productImageURL(
                                                    article.sdb_product
                                                      .articleNumber
                                                  ),
                                                },
                                                on: {
                                                  error:
                                                    _vm.fallbackPlaceholder,
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "col-4" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "form-control transparent",
                                        attrs: { readonly: "" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("getIsoSku")(article)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "col-3" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("div", { staticClass: "editable" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: article.qty,
                                            expression: "article.qty",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "number", min: "1" },
                                        domProps: { value: article.qty },
                                        on: {
                                          change: function ($event) {
                                            return _vm.updateArticleInProductBundle(
                                              article
                                            )
                                          },
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              article,
                                              "qty",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                  ]),
                                ]),
                                _c("div", { staticClass: "col-2" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn text-danger delete-article",
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteArticleFromProductBundle(
                                              article,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: ["fas", "trash"] },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]
                ),
              ],
              2
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col-3" }, [_c("strong", [_vm._v("Image")])]),
      _c("div", { staticClass: "col-3" }, [_c("strong", [_vm._v("Article")])]),
      _c("div", { staticClass: "col-3 p-0 text-center" }, [
        _c("strong", [_vm._v("Qty")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }