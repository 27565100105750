var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "card w-100" }, [
        _vm._m(0),
        _c("div", { staticClass: "card-body py-2" }, [
          _vm.uploads.length > 0
            ? _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.uploads, function (upload, cpUploadIndex) {
                  return _c(
                    "div",
                    { key: cpUploadIndex, staticClass: "col-3" },
                    [
                      _c("img", {
                        staticClass: "border img-fluid mb-2",
                        attrs: {
                          src: _vm.getThumbnailPath(upload.hashed_name),
                          alt: upload.file_name,
                          title: upload.file_name,
                        },
                        on: { error: _vm.fallbackPlaceholder },
                      }),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("strong", [_vm._v("Referenzen")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }