var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-100 d-flex flex-column" }, [
    _c("div", [
      _c("span", { staticClass: "font-weight-bold" }, [
        _vm._v(_vm._s(_vm.settings.headlinePlaceholder)),
      ]),
      _c("span", {
        staticClass: "text-muted small",
        domProps: { innerHTML: _vm._s(_vm.settings.description) },
      }),
    ]),
    _c(
      "div",
      {
        staticClass: "border px-3 py-3 singleProduct",
        class: { "border-danger": _vm.getErrorMsg("sdbProduct") },
      },
      [
        _c(
          "div",
          [
            _vm._m(0),
            _vm.singleArticleClone
              ? _c("div", { staticClass: "row listitem" }, [
                  _c("div", { staticClass: "col-3 mb-3" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal",
                            value: _vm.singleArticleClone.articleNumber + "",
                            expression: "singleArticleClone.articleNumber+''",
                          },
                        ],
                        staticClass: "d-flex",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "justify-content-center align-self-center",
                          },
                          [
                            _vm.singleArticleClone.articleNumber
                              ? _c("img", {
                                  staticClass: "img-fluid",
                                  attrs: {
                                    src: _vm.productImageURL(
                                      _vm.singleArticleClone.articleNumber
                                    ),
                                  },
                                  on: { error: _vm.fallbackPlaceholder },
                                })
                              : _vm._e(),
                            _c(
                              "b-modal",
                              {
                                attrs: {
                                  id: _vm.singleArticleClone.articleNumber,
                                  size: "lg",
                                  centered: "",
                                  "hide-footer": "",
                                  title: _vm._f("getIsoSku")(
                                    _vm.singleArticleClone
                                  ),
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "img-fluid",
                                  attrs: {
                                    src: _vm.productImageURL(
                                      _vm.singleArticleClone.articleNumber
                                    ),
                                  },
                                  on: { error: _vm.fallbackPlaceholder },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "col-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "p",
                        {
                          staticClass: "form-control transparent",
                          attrs: { readonly: "" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.singleArticleClone.title) + " "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-3" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "p",
                        {
                          staticClass: "form-control transparent",
                          attrs: { readonly: "" },
                        },
                        [_vm._v(" " + _vm._s(_vm.isoSku) + " ")]
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass:
                  "btn btn-block btn-sm text-white btn-primary cursor-pointer edit-productbundle",
                on: {
                  click: function ($event) {
                    _vm.modalShow = !_vm.modalShow
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("i18n.btn_change")) + " ")]
            ),
            _c(
              "b-modal",
              {
                attrs: {
                  size: "xl",
                  centered: "",
                  title: _vm.settings.headlinePlaceholder + " wählen",
                },
                scopedSlots: _vm._u([
                  {
                    key: "modal-footer",
                    fn: function ({ ok }) {
                      return [_c("div")]
                    },
                  },
                ]),
                model: {
                  value: _vm.modalShow,
                  callback: function ($$v) {
                    _vm.modalShow = $$v
                  },
                  expression: "modalShow",
                },
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("h3", [
                        _vm._v(
                          "Alle " + _vm._s(_vm.settings.headlinePlaceholder)
                        ),
                      ]),
                      _c("iass-wrapper", {
                        on: { articleAdded: _vm.addArticleFromSearch },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ]
    ),
    _c("div", {
      staticClass: "text-danger small",
      domProps: { innerHTML: _vm._s(_vm.getErrorMsg("sdbProduct")) },
    }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col-3" }, [_c("strong", [_vm._v("Bild")])]),
      _c("div", { staticClass: "col-6" }, [_c("strong", [_vm._v("Name")])]),
      _c("div", { staticClass: "col-3" }, [_c("strong", [_vm._v("Nummer")])]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }