var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "row" },
        [
          _vm._l(_vm.resultedImages, function (image, index) {
            return _c("div", { key: index, staticClass: "col-3 mb-4" }, [
              _c("div", { staticClass: "border h-100 p-2 image" }, [
                _c("div", { staticClass: "icons-left" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "btn btn-sm mr-2 btn-primary",
                      attrs: { title: _vm.$t("i18n.edit_image") },
                      on: {
                        click: function ($event) {
                          return _vm.openEditImageModal(image)
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "text-white",
                        attrs: { icon: ["fas", "pencil-alt"] },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "btn btn-sm btn-primary",
                      attrs: { title: _vm.$t("i18n.duplicate_image") },
                      on: {
                        click: function ($event) {
                          return _vm.openClonedImageModal(image)
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "text-white",
                        attrs: { icon: ["fas", "copy"] },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "icons-right" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "btn btn-sm mr-2 btn-danger",
                      attrs: { title: _vm.$t("i18n.delete_image") },
                      on: {
                        click: function ($event) {
                          return _vm.deleteVariantInCamera(image)
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "text-white",
                        attrs: { icon: ["fas", "times"] },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("img", {
                  staticClass: "img-fluid mb-2",
                  attrs: {
                    src: require("@/assets/images/image-placeholder.jpg"),
                    alt: "",
                  },
                }),
                _c("div", { staticClass: "h5" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "badge badge-warning mr-2",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("i18n.variant")) +
                          " " +
                          _vm._s(image.variant.id) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "badge badge-info",
                      attrs: { title: image.camera.text },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("i18n.camera")) +
                          " " +
                          _vm._s(image.camera.id) +
                          " "
                      ),
                    ]
                  ),
                ]),
              ]),
            ])
          }),
          _c("div", { staticClass: "col-3 mb-4" }, [
            _c(
              "div",
              {
                staticClass: "h-100 p-2 border d-flex text-center mb-4",
                staticStyle: { outline: "none !important" },
                on: {
                  click: function ($event) {
                    return _vm.openNewImageModal()
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex-grow-1 align-self-center cursor-pointer",
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "text-third",
                      staticStyle: { "font-size": "5em" },
                      attrs: { icon: ["fas", "plus"] },
                    }),
                    _c("div", { staticClass: "mt-3 d-block text-third" }, [
                      _vm._v(" " + _vm._s(_vm.$t("i18n.new_image")) + " "),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "new-image",
            title: _vm.$t("i18n.new_image_headline"),
            "hide-footer": "",
            "no-close-on-backdrop": "",
            scrollable: "",
            centered: "",
          },
        },
        [
          _vm.disabledCamera
            ? _c("p", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("i18n.new_image_desc_without_camera")) +
                    " "
                ),
              ])
            : _c("p", [
                _vm._v(
                  " " + _vm._s(_vm.$t("i18n.new_image_desc_with_camera")) + " "
                ),
              ]),
          _vm.variants.length > 0
            ? _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.variant.id,
                      expression: "form.variant.id",
                    },
                  ],
                  staticClass: "form-control mb-4",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.form.variant,
                        "id",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _vm._l(_vm.variants, function (variant, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: variant.id } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("i18n.variant")) +
                            " " +
                            _vm._s(variant.id) +
                            " "
                        ),
                      ]
                    )
                  }),
                  _c("option", { domProps: { value: _vm.maxVariant } }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("i18n.create_new_variant")) + " "
                    ),
                  ]),
                ],
                2
              )
            : _vm._e(),
          _vm.maxVariant == 1 || _vm.form.variant.id == _vm.maxVariant
            ? _c("div", [
                _c("label", [_vm._v("Variante " + _vm._s(_vm.maxVariant))]),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.variant.text,
                      expression: "form.variant.text",
                    },
                  ],
                  staticClass: "mb-4 form-control",
                  attrs: {
                    rows: "10",
                    placeholder: _vm.$t("i18n.new_variant_desc"),
                  },
                  domProps: { value: _vm.form.variant.text },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form.variant, "text", $event.target.value)
                    },
                  },
                }),
              ])
            : _vm._e(),
          _vm.clonedCamerasAndVariants.length > 0
            ? _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.camera.id,
                      expression: "form.camera.id",
                    },
                  ],
                  staticClass: "form-control mb-4",
                  class: { "d-none": _vm.disabledCamera },
                  attrs: { disabled: _vm.disabledCamera },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.form.camera,
                        "id",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _vm._l(
                    _vm.clonedCamerasAndVariants,
                    function (camera, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: camera.id } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("i18n.camera")) +
                              " " +
                              _vm._s(camera.id) +
                              ": " +
                              _vm._s(camera.text) +
                              " "
                          ),
                        ]
                      )
                    }
                  ),
                  _c("option", { domProps: { value: _vm.maxCamera } }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("i18n.create_new_camera")) + " "
                    ),
                  ]),
                ],
                2
              )
            : _vm._e(),
          (_vm.maxCamera == 1 || _vm.form.camera.id == _vm.maxCamera) &&
          !_vm.disabledCamera
            ? _c("div", [
                _c("label", [
                  _vm._v(
                    _vm._s(_vm.$t("i18n.camera")) + " " + _vm._s(_vm.maxCamera)
                  ),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.camera.text,
                      expression: "form.camera.text",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t("i18n.new_camera_desc"),
                  },
                  domProps: { value: _vm.form.camera.text },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form.camera, "text", $event.target.value)
                    },
                  },
                }),
              ])
            : _vm._e(),
          !_vm.combinationExists
            ? _c(
                "b-button",
                {
                  staticClass: "mt-4 float-right text-white",
                  attrs: {
                    size: "sm",
                    variant: "primary",
                    disabled: !_vm.newImageFormValidated,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.addVariantAndCamera()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t(_vm.saveButtonText)) + " ")]
              )
            : _c("div", { staticClass: "alert alert-warning" }, [
                _vm._v(" " + _vm._s(_vm.$t("i18n.combination_exists")) + " "),
              ]),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "edit-image",
            title: _vm.$t("i18n.edit_image"),
            "hide-footer": "",
            "no-close-on-backdrop": "",
            scrollable: "",
            centered: "",
          },
        },
        [
          _c("div", { staticClass: "form-group" }, [
            _c("label", [
              _vm._v(
                _vm._s(_vm.$t("i18n.variant")) +
                  " " +
                  _vm._s(_vm.form.variant.id)
              ),
            ]),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.variant.text,
                  expression: "form.variant.text",
                },
              ],
              staticClass: "form-control",
              attrs: {
                rows: "10",
                placeholder: _vm.$t("i18n.new_variant_desc"),
              },
              domProps: { value: _vm.form.variant.text },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form.variant, "text", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _c("small", [_vm._v(_vm._s(_vm.$t("i18n.edit_variant_desc")))]),
          ]),
          _c(
            "div",
            {
              staticClass: "form-group",
              class: { "d-none": _vm.disabledCamera },
            },
            [
              _c("label", [
                _vm._v(
                  _vm._s(_vm.$t("i18n.camera")) +
                    " " +
                    _vm._s(_vm.form.camera.id)
                ),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.camera.text,
                    expression: "form.camera.text",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  placeholder: _vm.$t("i18n.new_camera_desc"),
                  disabled: _vm.disabledCamera,
                },
                domProps: { value: _vm.form.camera.text },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form.camera, "text", $event.target.value)
                  },
                },
              }),
              _c("small", [_vm._v(_vm._s(_vm.$t("i18n.edit_camera_desc")))]),
            ]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-4 float-right text-white",
              attrs: {
                size: "sm",
                variant: "primary",
                disabled: !_vm.editImageFormValidated,
              },
              on: {
                click: function ($event) {
                  return _vm.saveChangesAfterEdit()
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    !_vm.editImageFormValidated
                      ? _vm.$t("i18n.fill_out_mendatory_fields")
                      : _vm.$t("i18n.save_changes")
                  ) +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }