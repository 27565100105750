var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", { staticClass: "fixed-top" }, [
    _c("nav", { staticClass: "py-0 bg-primary" }, [
      _c(
        "div",
        { staticClass: "d-inline float-left mt-3 ml-3" },
        [
          _c(
            "router-link",
            {
              staticClass: "text-white pointer",
              attrs: { id: "logo", to: { path: "/" } },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/obi-logo.svg"),
                  alt: "",
                },
              }),
              _c("span", [_vm._v("BriefingTool")]),
            ]
          ),
        ],
        1
      ),
      _c("ul", { staticClass: "navbar-nav flex-row float-right mt-2 mr-2" }, [
        _c("li", { staticClass: "nav-item" }, [
          _c("div", { staticClass: "btn btn-primary text-white" }, [
            _c("i", { staticClass: "fa fa-user" }),
            _vm._v(" " + _vm._s(_vm.username) + " "),
          ]),
        ]),
        _c("li", { staticClass: "nav-item" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip.bottom",
                  value: "Logout",
                  expression: "'Logout'",
                  modifiers: { bottom: true },
                },
              ],
              staticClass: "btn btn-primary px-2 text-white",
              on: { click: _vm.logout },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "sign-out-alt"] },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "container", attrs: { id: "mainnav" } }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("nav", { staticClass: "navbar p-0" }, [
              _c("ul", { staticClass: "navbar-nav flex-row" }, [
                _c(
                  "li",
                  { staticClass: "nav-item" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link",
                        attrs: { to: { path: "/projects" } },
                      },
                      [_vm._v(" Projects ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  { staticClass: "nav-item" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link",
                        attrs: { to: { path: "/briefings" } },
                      },
                      [_vm._v(" Briefings ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "bg-secondary" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-6" }, [
            this.$route.fullPath.includes("briefings")
              ? _c(
                  "nav",
                  {
                    staticClass: "navbar navbar-expand p-0",
                    attrs: { id: "subnav" },
                  },
                  [
                    _c("ul", { staticClass: "navbar-nav" }, [
                      _c(
                        "li",
                        { staticClass: "nav-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link",
                              attrs: { to: { name: "ContentPiecesList" } },
                            },
                            [_vm._v(" Content Pieces ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "li",
                        { staticClass: "nav-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link",
                              attrs: { to: { name: "BriefingArticleList" } },
                            },
                            [_vm._v(" Article Briefings ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "li",
                        { staticClass: "nav-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link",
                              attrs: { to: { name: "BriefingRenderingList" } },
                            },
                            [_vm._v(" Rendering Briefings ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              : _c("div", { staticClass: "navbar-height-fix" }),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }