var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "icon",
      class: [
        `icon--${_vm.name}`,
        `icon--${_vm.size}`,
        { "has-align-fix": _vm.fixAlign },
      ],
    },
    [
      _c("img", {
        attrs: {
          src: require(`@/assets/icons/${_vm.name}.svg`),
          alt: `${_vm.name}`,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }