var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.briefing.jira_export
    ? _c("span", { staticClass: "float-right" }, [
        _c(
          "a",
          {
            staticClass: "link text-default",
            attrs: { target: "_blank", href: _vm.jiraURL(_vm.briefing) },
          },
          [_vm._v(" " + _vm._s(_vm.briefing.jira_export.response.key) + " ")]
        ),
        _vm._v(" " + _vm._s(_vm.jiraStatus(_vm.briefing)) + " "),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }