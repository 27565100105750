var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.teams.length > 0
    ? _c(
        "div",
        [
          _c("new-briefing-modal", {
            attrs: { "briefing-type": _vm.briefingType },
          }),
        ],
        1
      )
    : _c("div", { staticClass: "container add-cp" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.bottom",
                value: _vm.$t("i18n.msg_not_a_member"),
                expression: "$t('i18n.msg_not_a_member')",
                modifiers: { bottom: true },
              },
            ],
            staticClass: "disabled",
            attrs: { id: "addContentPiece" },
          },
          [_c("font-awesome-icon", { attrs: { icon: ["fas", "plus"] } })],
          1
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }