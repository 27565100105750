var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _vm.product
      ? _c("div", { staticClass: "col-6 even-odd-row" }, [
          _c("div", { staticClass: "row" }, [
            _vm._m(0),
            _c("div", { staticClass: "col-8" }, [
              _vm._v(" " + _vm._s(_vm.product.sdbProduct.title) + " "),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _vm._m(1),
            _c("div", { staticClass: "col-8" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.product.sdbProduct.nationalArticleIdInclCheckDigit
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _vm._m(2),
            _c("div", { staticClass: "col-8" }, [
              _c("img", {
                staticClass: "border img-fluid mb-2",
                attrs: {
                  src: _vm.product.sdbProduct.imagelink,
                  width: "150",
                  alt: "",
                },
                on: { error: _vm.fallbackPlaceholder },
              }),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-4" }, [_c("strong", [_vm._v("Name")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-4" }, [
      _c("strong", [_vm._v("Artikelnummer")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-4" }, [_c("strong", [_vm._v("Bild")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }