var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "router-link",
          { staticClass: "back-arrow", attrs: { to: { name: "ProjectList" } } },
          [
            _c("font-awesome-icon", {
              attrs: { icon: ["fas", "chevron-left"] },
            }),
          ],
          1
        ),
        _vm.resultLoading
          ? _c("loading")
          : _c(
              "div",
              [
                _c("project-details-main", { attrs: { project: _vm.project } }),
                _c("div", { staticClass: "container" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("add-batch-button", {
                          attrs: {
                            "project-id": _vm.project.id,
                            team: _vm.project.team,
                          },
                        }),
                        _c("div", { staticClass: "clearfix mb-4" }),
                        _vm.axiosError
                          ? _c(
                              "div",
                              { staticClass: "col-12 alert-danger error mb-4" },
                              [_vm._v(" " + _vm._s(_vm.axiosError) + " ")]
                            )
                          : _vm._e(),
                        _vm._l(
                          _vm.project.batchSortOrder,
                          function (batchBriefingArticleId, index) {
                            return _c("batch-briefing-form", {
                              key: batchBriefingArticleId,
                              attrs: {
                                id: batchBriefingArticleId,
                                index: index,
                              },
                            })
                          }
                        ),
                      ],
                      2
                    ),
                  ]),
                ]),
              ],
              1
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }