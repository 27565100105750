var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-6 even-odd-row" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-5" }, [
          _c("strong", [_vm._v(_vm._s(_vm.$t("headline_name")))]),
        ]),
        _c("div", { staticClass: "col-7" }, [
          _vm._v(" " + _vm._s(_vm.briefing.name) + " "),
        ]),
      ]),
      _vm.briefing.topic
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-5" }, [
              _c("strong", [_vm._v(_vm._s(_vm.$t("headline_topic")) + " ")]),
            ]),
            _c("div", { staticClass: "col-7" }, [
              _vm._v(" " + _vm._s(_vm.briefing.topic) + " "),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-5" }, [
          _c("strong", [_vm._v(_vm._s(_vm.$t("headline_team")))]),
        ]),
        _c("div", { staticClass: "col-7" }, [
          _vm._v(" " + _vm._s(_vm.briefing.team) + " "),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-5" }, [
          _c("strong", [_vm._v(_vm._s(_vm.$t("headline_room")))]),
        ]),
        _c("div", { staticClass: "col-7" }, [
          _vm._v(" " + _vm._s(_vm.briefing.room) + " "),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-5" }, [
          _c("strong", [_vm._v(_vm._s(_vm.$t("headline_deadline")))]),
        ]),
        _c("div", { staticClass: "col-7" }, [
          _vm._v(
            " " +
              _vm._s(_vm._f("moment")(_vm.briefing.deadline, "DD.MM.Y")) +
              " "
          ),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-5" }, [
          _c("strong", [_vm._v(_vm._s(_vm.$t("headline_creator")))]),
        ]),
        _c("div", { staticClass: "col-7" }, [
          _vm._v(" " + _vm._s(_vm.userName) + " "),
        ]),
      ]),
      _c("div", { staticClass: "row mb-3" }, [
        _c("div", { staticClass: "col-5" }, [
          _c("strong", [_vm._v(_vm._s(_vm.$t("headline_created_at")))]),
        ]),
        _c("div", { staticClass: "col-7" }, [
          _vm._v(
            " " +
              _vm._s(_vm._f("moment")(_vm.briefing.created_at, "DD.MM.Y")) +
              " "
          ),
        ]),
      ]),
      _c("div", { staticClass: "row mb-3" }, [
        _c("div", { staticClass: "col-5" }, [
          _c("strong", [_vm._v(_vm._s(_vm.$t("headline_information")))]),
        ]),
        _c("div", {
          staticClass: "col-7",
          domProps: { innerHTML: _vm._s(_vm.formatedInformations) },
        }),
      ]),
    ]),
    _c("div", { staticClass: "col-6 readmore" }, [
      _vm.briefing.description
        ? _c(
            "div",
            [
              _c("p", [
                _c("strong", [
                  _vm._v(_vm._s(_vm.$t("headline_background_information"))),
                ]),
              ]),
              _c("truncate", {
                staticClass: "small",
                attrs: {
                  clamp: _vm.$t("more"),
                  length: 200,
                  less: _vm.$t("less"),
                  type: "html",
                  text: _vm.briefing.description,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.hasFormats
        ? _c(
            "div",
            { staticClass: "mt-3" },
            _vm._l(_vm.briefing.formats, function (format, index) {
              return _c("div", { key: index }, [
                _vm._v(" " + _vm._s(format.name)),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("selected_orientation")) +
                    ": " +
                    _vm._s(format.orientation)
                ),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("format_height")) +
                    ": " +
                    _vm._s(format.height) +
                    "px"
                ),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("format_width")) +
                    ": " +
                    _vm._s(format.width) +
                    "px"
                ),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("format_filetype")) +
                    ": " +
                    _vm._s(format.extension) +
                    " "
                ),
              ])
            }),
            0
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }