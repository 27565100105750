var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-4 d-flex align-items-stretch" }, [
    _c("div", { staticClass: "card w-100" }, [
      _c("div", { staticClass: "card-header" }, [
        _c("strong", [_vm._v(_vm._s(_vm.title))]),
      ]),
      _c(
        "div",
        { staticClass: "card-body even-odd-row" },
        [
          _c("div", { staticClass: "row" }, [
            _vm._m(0),
            _c("div", { staticClass: "col-4" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("moment")(
                      _vm.briefing.deadline
                        ? _vm.briefing.deadline
                        : _vm.contentPiece.deadline,
                      "DD.MM.Y"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _vm._l(_vm.briefing.options, function (option, optionIndex) {
            return _c("div", { key: optionIndex, staticClass: "row" }, [
              _c("div", { staticClass: "col-9" }, [
                _c("strong", [_vm._v(_vm._s(option.title))]),
              ]),
              _c(
                "div",
                { staticClass: "col-3" },
                [
                  option.checked == true
                    ? _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "check"] },
                      })
                    : _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "times"] },
                      }),
                ],
                1
              ),
            ])
          }),
          _vm.briefing.annotation
            ? _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12 readmore" },
                  [
                    _vm._m(1),
                    _c("truncate", {
                      staticClass: "small",
                      attrs: {
                        clamp: "mehr...",
                        length: 90,
                        less: "weniger",
                        type: "html",
                        text: _vm.briefing.annotation,
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.briefing.uploads && _vm.briefing.uploads.length > 0
            ? _c("div", { staticClass: "row" }, [
                _vm._m(2),
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(
                      _vm.briefing.uploads,
                      function (image, briefingUploadIndex) {
                        return _c(
                          "div",
                          { key: briefingUploadIndex, staticClass: "col-4" },
                          [
                            _c("img", {
                              staticClass: "border img-fluid mb-4",
                              attrs: {
                                src: _vm.getThumbnailPath(image.hashed_name),
                                alt: image.hashed_name,
                              },
                              on: { error: _vm.replaceByDefaultThumb },
                            }),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-8" }, [
      _c("strong", [_vm._v("Deadline")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-1" }, [
      _c("strong", [_vm._v("Anmerkung")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6" }, [
      _c("strong", [_vm._v("Referenzen")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }