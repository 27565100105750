var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: _vm.id, attrs: { id: _vm.id } },
    [
      _c(
        "router-link",
        {
          staticClass: "back-arrow",
          attrs: { to: { name: "ContentPiecesList" } },
        },
        [_c("font-awesome-icon", { attrs: { icon: ["fas", "chevron-left"] } })],
        1
      ),
      _c(
        "div",
        { staticClass: "container", attrs: { id: "cp-details" } },
        [
          _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("h1", { staticClass: "mb-0" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("i18n.headline_contentpiece")) + " "
                ),
              ]),
              _c("p", { staticClass: "mb-4" }, [
                _vm._v(" " + _vm._s(_vm.id) + " "),
              ]),
              _c("div", { staticClass: "pb-2" }, [
                _c("div", { staticClass: "editable" }, [
                  _c("label", [_vm._v(_vm._s(_vm.$t("i18n.label_name")))]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.name,
                        expression: "form.name",
                      },
                    ],
                    staticClass: "form-control w-100",
                    class: _vm.getValidationClass("name"),
                    attrs: { id: "cp-title", type: "text", maxlength: "80" },
                    domProps: { value: _vm.form.name },
                    on: {
                      change: _vm.save,
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "name", $event.target.value)
                      },
                    },
                  }),
                  _c("div", { staticClass: "form-text text-muted small" }, [
                    _vm._v(" " + _vm._s(_vm.$t("i18n.helper_name")) + " "),
                  ]),
                  _c("div", {
                    staticClass: "invalid-feedback",
                    domProps: { innerHTML: _vm._s(_vm.getErrorMsg("name")) },
                  }),
                ]),
              ]),
            ]),
          ]),
          _c("one-id-select", {
            staticClass: "mb-4",
            attrs: {
              bearer: _vm.bearer,
              "selected-id": _vm.form.internal_id,
              overlay: "false",
            },
          }),
          _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "pb-2" }, [
                _c("div", { staticClass: "editable" }, [
                  _c("label", [_vm._v(_vm._s(_vm.$t("i18n.label_topic")))]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.topic,
                        expression: "form.topic",
                      },
                    ],
                    staticClass: "form-control w-100",
                    attrs: { id: "cp-topic", type: "text" },
                    domProps: { value: _vm.form.topic },
                    on: {
                      change: _vm.save,
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "topic", $event.target.value)
                      },
                    },
                  }),
                  _c("div", { staticClass: "form-text text-muted small" }, [
                    _vm._v(" " + _vm._s(_vm.$t("i18n.helper_topic")) + " "),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row mb-4" }, [
            _c("div", { staticClass: "col-12 col-md-3" }, [
              _c("div", { staticClass: "pb-2" }, [
                _c("div", { staticClass: "editable" }, [
                  _c("label", [_vm._v(_vm._s(_vm.$t("i18n.headline_team")))]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.team,
                          expression: "form.team",
                        },
                      ],
                      staticClass: "form-control",
                      class: _vm.getValidationClass("team"),
                      attrs: { id: "cp-team" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.form,
                              "team",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          _vm.save,
                        ],
                      },
                    },
                    [
                      _c("option", { domProps: { value: null } }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("i18n.select_placeholder")) + " "
                        ),
                      ]),
                      _vm._l(_vm.teams, function (team) {
                        return _c(
                          "option",
                          { key: team.name, domProps: { value: team.name } },
                          [_vm._v(" " + _vm._s(team.name) + " ")]
                        )
                      }),
                    ],
                    2
                  ),
                  _c("div", {
                    staticClass: "invalid-feedback",
                    domProps: { innerHTML: _vm._s(_vm.getErrorMsg("team")) },
                  }),
                  _c("div", { staticClass: "form-text text-muted small" }, [
                    _vm._v(" " + _vm._s(_vm.$t("i18n.helper_team")) + " "),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-12 col-md-3" }, [
              _c("div", { staticClass: "editable" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("i18n.headline_room")))]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.room,
                        expression: "form.room",
                      },
                    ],
                    staticClass: "form-control",
                    class: _vm.getValidationClass("room"),
                    attrs: { id: "cp-room" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.form,
                            "room",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.roomUpdated($event)
                        },
                      ],
                    },
                  },
                  [
                    _c("option", [
                      _vm._v(_vm._s(_vm.$t("i18n.select_placeholder"))),
                    ]),
                    _vm._l(_vm.roomTypes, function (room) {
                      return _c(
                        "option",
                        { key: room, domProps: { value: room } },
                        [_vm._v(" " + _vm._s(room) + " ")]
                      )
                    }),
                  ],
                  2
                ),
                _c("div", {
                  staticClass: "invalid-feedback",
                  domProps: { innerHTML: _vm._s(_vm.getErrorMsg("room")) },
                }),
              ]),
            ]),
            _c("div", { staticClass: "col-12 col-md-3" }, [
              _c(
                "div",
                { staticClass: "editable" },
                [
                  _c("datepicker", {
                    attrs: {
                      errors: _vm.errors,
                      date: _vm.form.deadline,
                      settings: _vm.deadlineSettings,
                    },
                    on: { updateEvent: _vm.deadlineUpdated },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-12 col-md-3" }, [
              _c(
                "div",
                { staticClass: "editable" },
                [
                  _c("datepicker", {
                    attrs: {
                      errors: _vm.errors,
                      date: _vm.form.week,
                      settings: _vm.weekSettings,
                    },
                    on: { updateEvent: _vm.weekUpdated },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "row mb-5" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("label", [_vm._v(_vm._s(_vm.$t("i18n.label_description")))]),
            ]),
            _c("div", { staticClass: "col-12 col-lg-7 pb-3 pb-lg-0" }, [
              _c(
                "div",
                { staticClass: "mb-2 editable h-100", attrs: { id: "editor" } },
                [
                  _c("editor", {
                    attrs: {
                      id: "cp-desc",
                      placeholder: _vm.$t("i18n.placeholder_description"),
                      "initial-content": _vm.form.description,
                      "active-buttons": [
                        "bold",
                        "strike",
                        "underline",
                        "bullet_list",
                        "ordered_list",
                      ],
                    },
                    on: { blur: _vm.editorUpdated },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-12 col-lg-5" }, [
              _c("div", { staticClass: "alert-warning alert mb-0" }, [
                _c(
                  "ul",
                  { staticClass: "pl-2" },
                  _vm._l(
                    _vm.$t("i18n.helper_description"),
                    function (help, index) {
                      return _c("li", { key: index }, [
                        _vm._v(" " + _vm._s(help) + " "),
                      ])
                    }
                  ),
                  0
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 mb-2" }, [
              _c("h3", [_vm._v(_vm._s(_vm.$t("i18n.headline_instructions")))]),
              _c("p", [_vm._v(_vm._s(_vm.$t("i18n.helper_instructions")))]),
            ]),
          ]),
          _c("instruction", {
            attrs: {
              id: "upload-instructions",
              instructions: _vm.form.instructions,
              "content-piece-i-d": _vm.contentPiece.id,
            },
          }),
          _c("div", { staticClass: "row mt-5 row-eq-height" }, [
            _c(
              "div",
              { staticClass: "col-12 col-lg-6 col-xl-4 mb-5" },
              [
                !_vm.contentPiece.product_bundle
                  ? _c("products-bundle", {
                      attrs: {
                        "model-to-sync-with": "ContentPiece",
                        "product-bundle":
                          _vm.contentPiece.product_bundle_option,
                        "parent-i-d": _vm.contentPiece.id,
                        settings: _vm.optionsProductBundleSettings,
                      },
                    })
                  : _c("div", [
                      _c("h3", [
                        _vm._v(_vm._s(_vm.$t("i18n.headline_options_bundle"))),
                      ]),
                      _c("span", { staticClass: "text-muted small" }, [
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("i18n.not_available", {
                                  dependence: _vm.$t(
                                    "i18n.headline_products_bundle"
                                  ),
                                })
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12 col-lg-6 col-xl-4 mb-5" },
              [
                !_vm.contentPiece.product_bundle_option
                  ? _c("products-bundle", {
                      attrs: {
                        "model-to-sync-with": "ContentPiece",
                        "product-bundle": _vm.contentPiece.product_bundle,
                        "parent-i-d": _vm.contentPiece.id,
                        settings: _vm.articlesProductBundleSettings,
                      },
                    })
                  : _c("div", [
                      _c("h3", [
                        _vm._v(_vm._s(_vm.$t("i18n.headline_products_bundle"))),
                      ]),
                      _c("span", { staticClass: "text-muted small" }, [
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("i18n.not_available", {
                                  dependence: _vm.$t(
                                    "i18n.headline_options_bundle"
                                  ),
                                })
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12 col-lg-6 col-xl-4 mb-5" },
              [
                _c("products-bundle", {
                  attrs: {
                    "model-to-sync-with": "ContentPiece",
                    "product-bundle": _vm.contentPiece.tool_bundle,
                    "parent-i-d": _vm.contentPiece.id,
                    settings: _vm.toolsProductBundleSettings,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12 mb-2" },
              [
                _c("hr", { staticClass: "my-5" }),
                _c("h3", [_vm._v(_vm._s(_vm.$t("i18n.headline_references")))]),
                _c("span", { staticClass: "text-muted small" }, [
                  _c("p", [_vm._v(_vm._s(_vm.$t("i18n.helper_references")))]),
                ]),
                _c("upload", {
                  attrs: {
                    id: "upload-content-piece",
                    "upload-path": "content-pieces",
                    "view-style": "Grid",
                    "already-uploaded-files": _vm.contentPiece.uploads,
                    "uploadable-id": _vm.contentPiece.id,
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "col-12 mb-6" }, [
              _c("hr", { staticClass: "my-5" }),
              _c("h2", { staticClass: "mb-3 headline-with-badge" }, [
                _vm._v(" Briefings "),
                _c(
                  "span",
                  { staticClass: "badge badge-pill badge-third text-white" },
                  [_vm._v(_vm._s(_vm.contentPiece.briefing_renderings.length))]
                ),
              ]),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("add-briefing-tile", {
                    staticClass: "col-12 col-md-4 col-lg-3",
                    attrs: { "content-piece": _vm.contentPiece },
                  }),
                  _vm._l(
                    _vm.contentPiece.briefing_renderings,
                    function (briefing, index) {
                      return _c("briefing-tile", {
                        key: "renderingBriefing" + index,
                        attrs: {
                          briefing: briefing,
                          "briefing-type": "rendering",
                        },
                        on: { reloadContentpiece: _vm.reloadContentpiece },
                      })
                    }
                  ),
                  _vm._l(
                    _vm.contentPiece.briefing_show_flats,
                    function (briefing, index) {
                      return _c("briefing-tile", {
                        key: "showFlatBriefing" + index,
                        attrs: {
                          briefing: briefing,
                          "briefing-type": "show_flat",
                        },
                        on: { reloadContentpiece: _vm.reloadContentpiece },
                      })
                    }
                  ),
                  _vm.contentPiece.briefing_customer_instruction
                    ? _c("briefing-tile", {
                        attrs: {
                          briefing:
                            _vm.contentPiece.briefing_customer_instruction,
                          "briefing-type": "customer_instruction",
                        },
                        on: { reloadContentpiece: _vm.reloadContentpiece },
                      })
                    : _vm._e(),
                  _vm.contentPiece.briefing_video
                    ? _c("briefing-tile", {
                        attrs: {
                          briefing: _vm.contentPiece.briefing_video,
                          "briefing-type": "video",
                        },
                        on: { reloadContentpiece: _vm.reloadContentpiece },
                      })
                    : _vm._e(),
                  _vm.contentPiece.briefing_text
                    ? _c("briefing-tile", {
                        attrs: {
                          briefing: _vm.contentPiece.briefing_text,
                          "briefing-type": "text",
                        },
                        on: { reloadContentpiece: _vm.reloadContentpiece },
                      })
                    : _vm._e(),
                  _vm.contentPiece.briefing_stock_image
                    ? _c("briefing-tile", {
                        attrs: {
                          briefing: _vm.contentPiece.briefing_stock_image,
                          "briefing-type": "stock_image",
                        },
                        on: { reloadContentpiece: _vm.reloadContentpiece },
                      })
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }