var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.resultLoading ? _c("loading") : _vm._e(),
      _c("transition", { attrs: { name: "fadein" } }, [
        !_vm.resultLoading
          ? _c(
              "div",
              [
                !_vm.contentPiece.ordered
                  ? _c("content-piece-details-view", {
                      attrs: { id: _vm.id, "content-piece": _vm.contentPiece },
                    })
                  : _c("content-piece-details-print", {
                      attrs: { id: _vm.id, "content-piece": _vm.contentPiece },
                    }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "transition",
        { attrs: { name: "fadein" } },
        [
          !_vm.resultLoading
            ? _c("publish-content-piece-bar", {
                attrs: {
                  "content-piece-i-d": _vm.contentPiece.id,
                  ordered: _vm.contentPiece.ordered,
                  "ready-to-finalize": _vm.readyToFinalize,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }