var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "new-contentpiece-modal",
      attrs: {
        id: "new-contentpiece",
        "hide-footer": "",
        centered: "",
        title: _vm.$t("i18n.headline_create_contentpiece"),
      },
    },
    [
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.save.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "form-group mt-4 mb-4" }, [
            _c("div", { staticClass: "editable" }, [
              _c("label", { attrs: { for: "cp-name" } }, [
                _vm._v(_vm._s(_vm.$t("i18n.label_name"))),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.name,
                    expression: "form.name",
                  },
                ],
                staticClass: "form-control",
                class: _vm.getValidationClass("name"),
                attrs: {
                  id: "cp-name",
                  type: "text",
                  maxlength: "80",
                  autocomplete: "off",
                },
                domProps: { value: _vm.form.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "name", $event.target.value)
                  },
                },
              }),
              _c("div", {
                staticClass: "invalid-feedback",
                domProps: { innerHTML: _vm._s(_vm.getErrorMsg("name")) },
              }),
              _c("div", { staticClass: "form-text text-muted small" }, [
                _vm._v(" " + _vm._s(_vm.$t("i18n.helper_name")) + " "),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row mb-4" }, [
            _c("div", { staticClass: "col-12" }, [
              _vm.teams.length > 0
                ? _c("div", { staticClass: "pb-2" }, [
                    _c("div", { staticClass: "editable" }, [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("i18n.headline_teams"))),
                      ]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedTeam,
                              expression: "selectedTeam",
                            },
                          ],
                          staticClass: "form-control",
                          class: _vm.getValidationClass("team"),
                          attrs: { id: "cp-team" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selectedTeam = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _c("option", [
                            _vm._v(_vm._s(_vm.$t("i18n.select_placeholder"))),
                          ]),
                          _vm._l(_vm.teams, function (team, index) {
                            return _c(
                              "option",
                              { key: index, domProps: { value: team.name } },
                              [_vm._v(" " + _vm._s(team.name) + " ")]
                            )
                          }),
                        ],
                        2
                      ),
                      _c("div", {
                        staticClass: "invalid-feedback",
                        domProps: {
                          innerHTML: _vm._s(_vm.getErrorMsg("team")),
                        },
                      }),
                    ]),
                  ])
                : _c("div", { staticClass: "pb-2" }, [
                    _vm._v(" No team available "),
                  ]),
            ]),
          ]),
          _c("div", { staticClass: "row mb-4" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "editable" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("i18n.headline_room")))]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedRoom,
                        expression: "selectedRoom",
                      },
                    ],
                    staticClass: "form-control",
                    class: _vm.getValidationClass("room"),
                    attrs: { id: "cp-room" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedRoom = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", [
                      _vm._v(_vm._s(_vm.$t("i18n.select_placeholder"))),
                    ]),
                    _vm._l(_vm.roomTypes, function (room) {
                      return _c(
                        "option",
                        { key: room, domProps: { value: room } },
                        [_vm._v(" " + _vm._s(room) + " ")]
                      )
                    }),
                  ],
                  2
                ),
                _c("div", {
                  staticClass: "invalid-feedback",
                  domProps: { innerHTML: _vm._s(_vm.getErrorMsg("room")) },
                }),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row mb-4" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "div",
                { staticClass: "editable" },
                [
                  _c("datepicker", {
                    attrs: {
                      errors: _vm.errors,
                      date: _vm.form.deadline,
                      settings: _vm.datepickerSettings,
                    },
                    on: { updateEvent: _vm.deadlineUpdated },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "button",
            {
              staticClass: "btn btn-default float-right",
              attrs: { id: "save-cp", disabled: _vm.submitInProgress },
            },
            [_vm._v(" " + _vm._s(_vm.$t("i18n.btn_save")) + " ")]
          ),
          _c("div", { staticClass: "clearfix" }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }