var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "shadow mb-5 pt-3",
      attrs: { id: "project-details-main-form" },
    },
    [
      _vm.collapsedView
        ? _c("div", {}, [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-8" }, [
                  _c("p", { staticClass: "font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.form.name)),
                  ]),
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.form.description) },
                  }),
                ]),
                _c("div", { staticClass: "col-4 text-right" }, [
                  _c("p", [
                    _c("b", [_vm._v("Team:")]),
                    _vm._v(" " + _vm._s(_vm.project.team)),
                    _c("br"),
                    _c("b", [_vm._v("Deadline:")]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("moment")(_vm.form.deadline, "DD.MM.YYYY")
                        )
                    ),
                  ]),
                ]),
              ]),
            ]),
          ])
        : _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "container", attrs: { id: "br-details" } },
                [
                  _c("div", { staticClass: "mb-4" }, [
                    _c("div", { staticClass: "editable" }, [
                      _c("label", [_vm._v(_vm._s(_vm.$t("label_name")))]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.name,
                            expression: "form.name",
                          },
                        ],
                        staticClass: "form-control w-100",
                        class: _vm.getValidationClass("name"),
                        attrs: {
                          id: "cp-title",
                          type: "text",
                          maxlength: "80",
                        },
                        domProps: { value: _vm.form.name },
                        on: {
                          change: _vm.update,
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "name", $event.target.value)
                          },
                        },
                      }),
                      _c("div", { staticClass: "form-text text-muted small" }, [
                        _vm._v(" " + _vm._s(_vm.$t("helper_name")) + " "),
                      ]),
                      _c("div", {
                        staticClass: "invalid-feedback",
                        domProps: {
                          innerHTML: _vm._s(_vm.getErrorMsg("name")),
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "row mb-4" }, [
                    _c("div", { staticClass: "col-12 col-md-3" }, [
                      _c("div", [
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t("label_internal_id"))),
                        ]),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: { disabled: "" },
                          domProps: { value: _vm.project.internalId },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-12 col-md-3" }, [
                      _c("label", [_vm._v(_vm._s(_vm.$t("label_team")))]),
                      _c("input", {
                        staticClass: "form-control",
                        attrs: { disabled: "" },
                        domProps: { value: _vm.project.team },
                      }),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-3" },
                      [
                        _c("datepicker", {
                          attrs: {
                            date: _vm.form.deadline,
                            errors: _vm.errors,
                            settings: _vm.deadlineSettings,
                          },
                          on: { updateEvent: _vm.deadlineUpdated },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("label_description"))),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-12 mb-6" }, [
                      _c(
                        "div",
                        { staticClass: "editable h-100" },
                        [
                          _c("editor", {
                            attrs: {
                              "initial-content": _vm.form.description,
                              placeholder: _vm.$t("placeholder_description"),
                              "active-buttons": [
                                "bold",
                                "strike",
                                "underline",
                                "bullet_list",
                                "ordered_list",
                              ],
                            },
                            on: { blur: _vm.updateDescription },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
      _c(
        "div",
        {
          staticClass: "float-right mr-3 mb-2 readmoreButton",
          attrs: { role: "button" },
          on: {
            click: function ($event) {
              _vm.collapsedView = !_vm.collapsedView
            },
          },
        },
        [
          _vm.collapsedView
            ? _c(
                "span",
                [
                  _vm._v(_vm._s(_vm.$t("btn_more")) + " "),
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "chevron-down"] },
                  }),
                ],
                1
              )
            : _c(
                "span",
                [
                  _vm._v(_vm._s(_vm.$t("btn_less")) + " "),
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "chevron-up"] },
                  }),
                ],
                1
              ),
        ]
      ),
      _c("div", { staticClass: "clearfix" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }