var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.teams.length > 0
    ? _c(
        "div",
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal",
                  value: "new-contentpiece",
                  expression: "'new-contentpiece'",
                },
              ],
              staticClass:
                "float-right btn btn-outline-primary ml-2 mb-4 mb-xl-0",
              attrs: { id: "addContentPiece" },
            },
            [
              _c("font-awesome-icon", { attrs: { icon: ["fas", "plus"] } }),
              _vm._v(" Neu "),
            ],
            1
          ),
          _c("new-content-piece-modal"),
        ],
        1
      )
    : _c("div", { staticClass: "container add-cp" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.bottom",
                value: _vm.$t("i18n.msg_not_a_member"),
                expression: "$t('i18n.msg_not_a_member')",
                modifiers: { bottom: true },
              },
            ],
            staticClass: "disabled",
            attrs: { id: "addContentPiece" },
          },
          [_c("font-awesome-icon", { attrs: { icon: ["fas", "plus"] } })],
          1
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }