var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "briefing-tile col-12 col-md-4 col-lg-3" }, [
    _c(
      "div",
      { staticClass: "tile p-2 mb-4", on: { click: _vm.openBriefing } },
      [
        _c(
          "div",
          {
            staticClass: "briefing-tile-wrapper mb-2 bg-third",
            class: "briefing-" + _vm.briefingType,
            attrs: { "data-test": "rendering-wrap" },
          },
          [
            _c("img", {
              staticClass: "img-fluid img-top",
              attrs: {
                src: require(`@/assets/images/${
                  _vm.briefingTypes[_vm.briefingType].img
                }`),
                alt: "",
              },
            }),
            _c("div", { staticClass: "info name" }, [
              _vm._v(
                " " + _vm._s(_vm.briefingTypes[_vm.briefingType].title) + " "
              ),
            ]),
            _c("div", { staticClass: "info date" }, [
              _c("small", [
                _vm._v(
                  _vm._s(_vm._f("moment")(_vm.briefing.created_at, "D.MM.Y"))
                ),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "briefing-delete btn-danger btn",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.deleteBriefing()
                  },
                },
              },
              [_c("font-awesome-icon", { attrs: { icon: ["fas", "trash"] } })],
              1
            ),
            _vm.briefing.send == 1
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.top",
                        value: _vm.$t("i18n.helper_locked"),
                        expression: "$t('i18n.helper_locked')",
                        modifiers: { top: true },
                      },
                    ],
                    staticClass: "lock-layer",
                  },
                  [
                    _c("div", { staticClass: "row h-100 align-items-center" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 text-center" },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "lock"] },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _vm.briefingType == "rendering" || _vm.briefingType == "show_flat"
          ? _c(
              "div",
              { staticClass: "p-2", attrs: { "data-test": "br-wrap" } },
              [
                _c("h5", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.briefing.name
                          ? _vm.briefing.name
                          : _vm.briefingTypes[_vm.briefingType].title
                      ) +
                      " Szene " +
                      _vm._s(_vm.briefing.scene) +
                      " "
                  ),
                ]),
                _c("div", { domProps: { innerHTML: _vm._s(_vm.description) } }),
              ]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }