var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-100" },
    [
      _c(
        "router-link",
        { staticClass: "back-arrow", attrs: { to: _vm.linkBack } },
        [_c("font-awesome-icon", { attrs: { icon: ["fas", "chevron-left"] } })],
        1
      ),
      _c(_vm.briefingType, {
        tag: "component",
        attrs: { id: _vm.briefingId, "content-piece-i-d": _vm.contentPieceId },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }