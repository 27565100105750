import { render, staticRenderFns } from "./TeamSelect.vue?vue&type=template&id=2c4ea07f&"
import script from "./TeamSelect.vue?vue&type=script&lang=ts&"
export * from "./TeamSelect.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./TeamSelect.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/custom-builds/RenderThat/aws/briefing-tool-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2c4ea07f')) {
      api.createRecord('2c4ea07f', component.options)
    } else {
      api.reload('2c4ea07f', component.options)
    }
    module.hot.accept("./TeamSelect.vue?vue&type=template&id=2c4ea07f&", function () {
      api.rerender('2c4ea07f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/project/forms/TeamSelect.vue"
export default component.exports