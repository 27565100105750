var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "batch-briefing-block border p-3 bg-white mb-4 cp mx-1 mx-sm-0 position-relative pb-4",
      class: _vm.briefing.ordered ? "ordered" : "not-ordered",
      attrs: { id: _vm.briefing.id },
    },
    [
      _c(
        "b-dropdown",
        {
          staticClass: "float-right",
          attrs: {
            text: "...",
            dropleft: "",
            variant: "link",
            "toggle-class": "text-decoration-none text-default",
            "no-caret": "",
          },
          scopedSlots: _vm._u([
            {
              key: "button-content",
              fn: function () {
                return [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "ellipsis-v"] },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "b-dropdown-item",
            {
              staticClass: "text-right",
              attrs: {
                disabled:
                  _vm.briefing.productUniques.length === 0 ||
                  _vm.briefing.ordered,
              },
              on: {
                click: function ($event) {
                  _vm.confirmModalOpen = true
                },
              },
            },
            [
              _c(
                "span",
                { staticClass: "text-success" },
                [
                  _vm._v(_vm._s(_vm.$t("option_order_briefing")) + " "),
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "paper-plane"] },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-dropdown-item",
            {
              staticClass: "text-right",
              attrs: { disabled: _vm.briefing.ordered },
              on: { click: _vm.deleteBriefing },
            },
            [
              _c(
                "span",
                { staticClass: "text-danger" },
                [
                  _vm._v(_vm._s(_vm.$t("option_delete_briefing")) + " "),
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "trash"] },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm.index !== 0
            ? _c(
                "b-dropdown-item",
                { staticClass: "text-right", on: { click: _vm.moveOrderUp } },
                [
                  _vm._v(" " + _vm._s(_vm.$t("option_move_up")) + " "),
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "chevron-up"] },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.index + 1 !== _vm.batchBriefingsCount
            ? _c(
                "b-dropdown-item",
                { staticClass: "text-right", on: { click: _vm.moveOrderDown } },
                [
                  _vm._v(" " + _vm._s(_vm.$t("option_move_down")) + " "),
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "chevron-down"] },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.collapsedView
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-10" }, [
              _c("h4", { staticClass: "d-inline" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.form.name ? _vm.form.name : _vm.$t("new_list")) +
                    " "
                ),
              ]),
              _c("span", { staticClass: "muted small" }, [
                _vm._v(
                  " - " +
                    _vm._s(
                      _vm.briefing.ordered
                        ? _vm.$t("status_ordered")
                        : _vm.$t("status_not_ordered")
                    )
                ),
              ]),
              _c("div", { staticClass: "mt-3" }, [
                _vm.form.description
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("truncate")(
                              _vm.form.description,
                              200,
                              " [...]"
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _c("div", [
                      _vm._v(" " + _vm._s(_vm.$t("no_description")) + " "),
                    ]),
              ]),
            ]),
            _c("div", { staticClass: "col-2" }, [
              _c("div", { staticClass: "small" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("label_deadline")) +
                    ": " +
                    _vm._s(_vm._f("moment")(_vm.briefing.deadline, "DD.MM.Y")) +
                    " "
                ),
              ]),
              _c("span", { staticClass: "d-inline-block small mt-2" }, [
                _c(
                  "span",
                  {
                    staticClass: "badge text-white",
                    class:
                      _vm.ModelsCount > 0 ? "badge-primary" : "badge-third",
                  },
                  [_vm._v(_vm._s(_vm.ModelsCount))]
                ),
                _vm._v(" " + _vm._s(_vm.$t("label_3d_models"))),
              ]),
              _c("span", { staticClass: "d-inline-block small" }, [
                _c(
                  "span",
                  {
                    staticClass: "badge text-white",
                    class:
                      _vm.LowPolyModelsCount > 0
                        ? "badge-primary"
                        : "badge-third",
                  },
                  [_vm._v(_vm._s(_vm.LowPolyModelsCount))]
                ),
                _vm._v(" " + _vm._s(_vm.$t("label_low_poly_models"))),
              ]),
            ]),
          ])
        : _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 mb-4" }, [
              _c("h2", { staticClass: "d-inline" }, [
                _vm._v(" " + _vm._s(_vm.$t("headline")) + " "),
              ]),
              _c("span", { staticClass: "muted small" }, [
                _vm._v(
                  " - " +
                    _vm._s(
                      _vm.briefing.ordered
                        ? _vm.$t("status_ordered")
                        : _vm.$t("status_not_ordered")
                    )
                ),
              ]),
            ]),
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "mb-4" }, [
                _c("div", { staticClass: "editable" }, [
                  _c("label", [_vm._v(_vm._s(_vm.$t("label_name")))]),
                  !_vm.briefing.ordered
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.name,
                            expression: "form.name",
                          },
                        ],
                        staticClass: "form-control w-100",
                        class: _vm.getValidationClass("name"),
                        attrs: {
                          id: "cp-title",
                          type: "text",
                          maxlength: "80",
                        },
                        domProps: { value: _vm.form.name },
                        on: {
                          change: _vm.update,
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "name", $event.target.value)
                          },
                        },
                      })
                    : _c("p", [_vm._v(" " + _vm._s(_vm.form.name) + " ")]),
                  !_vm.briefing.ordered
                    ? _c("div", { staticClass: "form-text text-muted small" }, [
                        _vm._v(" " + _vm._s(_vm.$t("helper_name")) + " "),
                      ])
                    : _vm._e(),
                  _c("div", {
                    staticClass: "invalid-feedback",
                    domProps: { innerHTML: _vm._s(_vm.getErrorMsg("name")) },
                  }),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "col-4" },
              [
                !_vm.briefing.ordered
                  ? _c("datepicker", {
                      attrs: {
                        date: _vm.form.deadline,
                        errors: _vm.errors,
                        settings: _vm.deadlineSettings,
                      },
                      on: { updateEvent: _vm.deadlineUpdated },
                    })
                  : _c("div", [
                      _c("label", [_vm._v(_vm._s(_vm.$t("label_deadline")))]),
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm._f("moment")(_vm.form.deadline, "DD.MM.Y"))
                        ),
                      ]),
                    ]),
              ],
              1
            ),
            _c("div", { staticClass: "col-4 mb-4" }, [
              _vm.briefing.ordered
                ? _c("div", [_vm._v(" " + _vm._s(_vm.form.room) + " ")])
                : _c("div", { staticClass: "editable" }, [
                    _c("label", [_vm._v(_vm._s(_vm.$t("headline_room")))]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.room,
                            expression: "form.room",
                          },
                        ],
                        staticClass: "form-control",
                        class: _vm.getValidationClass("room"),
                        attrs: { id: "cp-room" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.form,
                                "room",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            _vm.update,
                          ],
                        },
                      },
                      [
                        _c("option", [
                          _vm._v(_vm._s(_vm.$t("select_placeholder"))),
                        ]),
                        _vm._l(_vm.roomTypes, function (room) {
                          return _c(
                            "option",
                            { key: room, domProps: { value: room } },
                            [_vm._v(" " + _vm._s(room) + " ")]
                          )
                        }),
                      ],
                      2
                    ),
                    _c("div", {
                      staticClass: "invalid-feedback",
                      domProps: { innerHTML: _vm._s(_vm.getErrorMsg("room")) },
                    }),
                  ]),
            ]),
            _c("div", { staticClass: "col-12" }, [
              _c("label", [_vm._v(_vm._s(_vm.$t("label_description")))]),
            ]),
            _c("div", { staticClass: "col-12 mb-4" }, [
              _c(
                "div",
                { staticClass: "editable h-100" },
                [
                  !_vm.briefing.ordered
                    ? _c("editor", {
                        attrs: {
                          "initial-content": _vm.form.description,
                          placeholder: _vm.$t("placeholder_description"),
                          "active-buttons": [
                            "bold",
                            "strike",
                            "underline",
                            "bullet_list",
                            "ordered_list",
                          ],
                        },
                        on: { blur: _vm.updateDescription },
                      })
                    : _c("div", [
                        _vm.form.description
                          ? _c("div", {
                              domProps: {
                                innerHTML: _vm._s(_vm.form.description),
                              },
                            })
                          : _c("div", [
                              _vm._v(
                                " " + _vm._s(_vm.$t("no_description")) + " "
                              ),
                            ]),
                      ]),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-12 mb-4" }, [
              _c("label", [_vm._v(_vm._s(_vm.$t("headline_create_low_poly")))]),
            ]),
            !_vm.briefing.ordered
              ? _c("div", { staticClass: "col-12 mb-4" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-2" }, [
                      _c(
                        "div",
                        { staticClass: "orderType selected" },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "check-circle"] },
                          }),
                          _vm._m(0),
                          _c("p", { staticClass: "text-center mt-2" }, [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.$t("label_3d_models"))),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-2" }, [
                      _c(
                        "div",
                        {
                          staticClass: "orderType",
                          class: { selected: _vm.form.createLowPoly },
                          on: { click: _vm.updateCreateLowPoly },
                        },
                        [
                          _vm.form.createLowPoly
                            ? _c("font-awesome-icon", {
                                attrs: { icon: ["fas", "check-circle"] },
                              })
                            : _vm._e(),
                          _vm._m(1),
                          _c("p", { staticClass: "text-center mt-2" }, [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.$t("label_low_poly_models"))),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ])
              : _c("div", { staticClass: "col-12 mb-4" }, [
                  _c("ul", [
                    _c("li", [_vm._v(_vm._s(_vm.$t("label_3d_models")))]),
                    _vm.form.createLowPoly
                      ? _c("li", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("label_low_poly_models")) + " "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
            _c("div", { staticClass: "col-12" }, [
              _c("h3", [_vm._v(_vm._s(_vm.$t("headline_references")))]),
              !_vm.briefing.ordered
                ? _c("p", { staticClass: "text-muted small" }, [
                    _vm._v(" " + _vm._s(_vm.$t("helper_references")) + " "),
                  ])
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                !_vm.briefing.ordered
                  ? _c("upload", {
                      attrs: {
                        "upload-path": "batch-briefing-article",
                        "view-style": "Grid",
                        "already-uploaded-files": _vm.briefing.uploads,
                        "uploadable-id": _vm.briefing.id,
                      },
                      on: {
                        updated: function ($event) {
                          return _vm.updateBatchBriefing()
                        },
                      },
                    })
                  : _c("uploads", {
                      staticClass: "mb-4",
                      attrs: {
                        "view-style": "Grid",
                        "uploaded-files": _vm.briefing.uploads,
                      },
                    }),
                _c("hr", { staticClass: "my-4" }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12 mb-4" },
              [
                _c("products", {
                  attrs: {
                    settings: _vm.articlesSettings,
                    "briefing-id": _vm.briefing.id,
                    editable: !_vm.briefing.ordered,
                    products: _vm.briefing.productUniques,
                  },
                  on: {
                    updated: function ($event) {
                      return _vm.updateBatchBriefing()
                    },
                  },
                }),
              ],
              1
            ),
          ]),
      _c(
        "div",
        {
          staticClass: "readmoreButton",
          attrs: { role: "button" },
          on: {
            click: function ($event) {
              _vm.collapsedView = !_vm.collapsedView
            },
          },
        },
        [
          _vm.collapsedView
            ? _c(
                "span",
                [
                  _vm._v(_vm._s(_vm.$t("btn_more")) + " "),
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "chevron-down"] },
                  }),
                ],
                1
              )
            : _c(
                "span",
                [
                  _vm._v(_vm._s(_vm.$t("btn_less")) + " "),
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "chevron-up"] },
                  }),
                ],
                1
              ),
        ]
      ),
      _c("div", { staticClass: "clearfix" }),
      _c("confirm-modal", {
        attrs: { briefing: _vm.briefing, "modal-open": _vm.confirmModalOpen },
        on: {
          closeConfirmModal: function ($event) {
            return _vm.closeConfirmModal()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "image" }, [
      _c("img", {
        staticClass: "img-fluid",
        attrs: {
          src: require("@/assets/images/briefing-3d-icon.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "image" }, [
      _c("img", {
        staticClass: "img-fluid",
        attrs: {
          src: require("@/assets/images/briefing-3d-icon.png"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }