import { render, staticRenderFns } from "./BriefingDeadline.vue?vue&type=template&id=2921a14a&"
import script from "./BriefingDeadline.vue?vue&type=script&lang=js&"
export * from "./BriefingDeadline.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./BriefingDeadline.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/custom-builds/RenderThat/aws/briefing-tool-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2921a14a')) {
      api.createRecord('2921a14a', component.options)
    } else {
      api.reload('2921a14a', component.options)
    }
    module.hot.accept("./BriefingDeadline.vue?vue&type=template&id=2921a14a&", function () {
      api.rerender('2921a14a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/shared/BriefingDeadline.vue"
export default component.exports