var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "router-link",
        {
          staticClass: "back-arrow",
          attrs: { to: { name: "ContentPiecesList" } },
        },
        [_c("font-awesome-icon", { attrs: { icon: ["fas", "chevron-left"] } })],
        1
      ),
      _c("div", { staticClass: "container pb-8" }, [
        _vm._m(0),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "card w-100" }, [
              _c("div", { staticClass: "card-header" }, [
                _c("strong", [_vm._v("Contentpiece")]),
                _vm.contentPiece.jira_export
                  ? _c("span", { staticClass: "float-right" }, [
                      _c(
                        "a",
                        {
                          staticClass: "link text-default",
                          attrs: {
                            target: "_blank",
                            href:
                              "https://jira.obi.net/browse/" +
                              _vm.contentPiece.jira_export.response.key,
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.contentPiece.jira_export.response.key)
                          ),
                        ]
                      ),
                      _vm._v(
                        _vm._s(
                          _vm.contentPiece.jira_export.status != null
                            ? " - " + _vm.contentPiece.jira_export.status.name
                            : ""
                        )
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "card-body py-2" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-6 even-odd-row" }, [
                    _c("div", { staticClass: "row" }, [
                      _vm._m(1),
                      _c("div", { staticClass: "col-7" }, [
                        _vm._v(" " + _vm._s(_vm.contentPiece.name) + " "),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _vm._m(2),
                      _c("div", { staticClass: "col-7" }, [
                        _vm._v(
                          " " + _vm._s(_vm.contentPiece.internal_id) + " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _vm._m(3),
                      _c("div", { staticClass: "col-7" }, [
                        _vm._v(" " + _vm._s(_vm.contentPiece.topic) + " "),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _vm._m(4),
                      _c("div", { staticClass: "col-7" }, [
                        _vm._v(" " + _vm._s(_vm.contentPiece.team) + " "),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _vm._m(5),
                      _c("div", { staticClass: "col-7" }, [
                        _vm._v(" " + _vm._s(_vm.contentPiece.room) + " "),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _vm._m(6),
                      _c("div", { staticClass: "col-7" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("moment")(
                                _vm.contentPiece.deadline,
                                "DD.MM.Y"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _vm._m(7),
                      _c("div", { staticClass: "col-7" }, [
                        _vm._v(" " + _vm._s(_vm.userName) + " "),
                      ]),
                    ]),
                    _c("div", { staticClass: "row mb-3" }, [
                      _vm._m(8),
                      _c("div", { staticClass: "col-7" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("moment")(
                                _vm.contentPiece.created_at,
                                "DD.MM.Y"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                  _vm.contentPiece.product_bundle
                    ? _c("div", { staticClass: "col-3" }, [
                        _vm._m(9),
                        _c(
                          "table",
                          { staticClass: "table table-striped" },
                          [
                            _vm._m(10),
                            _vm._l(
                              _vm.contentPiece.product_bundle.products,
                              function (product, productIndex) {
                                return _c(
                                  "tr",
                                  { key: "product" + productIndex },
                                  [
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(_vm._f("getIsoSku")(product))
                                      ),
                                    ]),
                                    _c("td", [_vm._v(_vm._s(product.qty))]),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ])
                    : _vm._e(),
                  _vm.contentPiece.product_bundle_option
                    ? _c("div", { staticClass: "col-3" }, [
                        _vm._m(11),
                        _c(
                          "table",
                          { staticClass: "table table-striped" },
                          [
                            _vm._m(12),
                            _vm._l(
                              _vm.contentPiece.product_bundle_option.products,
                              function (option, optionIndex) {
                                return _c(
                                  "tr",
                                  { key: "option" + optionIndex },
                                  [
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(_vm._f("getIsoSku")(option))
                                      ),
                                    ]),
                                    _c("td", [_vm._v(_vm._s(option.qty))]),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ])
                    : _vm._e(),
                  _vm.contentPiece.tool_bundle
                    ? _c("div", { staticClass: "col-3" }, [
                        _vm._m(13),
                        _c(
                          "table",
                          { staticClass: "table table-striped" },
                          [
                            _vm._m(14),
                            _vm._l(
                              _vm.contentPiece.tool_bundle.products,
                              function (tool, toolIndex) {
                                return _c("tr", { key: "tool" + toolIndex }, [
                                  _c("td", [
                                    _vm._v(_vm._s(_vm._f("getIsoSku")(tool))),
                                  ]),
                                  _c("td", [_vm._v(_vm._s(tool.qty))]),
                                ])
                              }
                            ),
                          ],
                          2
                        ),
                      ])
                    : _vm._e(),
                  _vm.contentPiece.description
                    ? _c(
                        "div",
                        { staticClass: "col-6 readmore" },
                        [
                          _vm._m(15),
                          _c("truncate", {
                            staticClass: "small",
                            attrs: {
                              clamp: "mehr...",
                              length: 200,
                              less: "weniger",
                              type: "html",
                              text: _vm.contentPiece.description,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.contentPiece.instructions.length > 0
                    ? _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _vm._m(16),
                          _vm._l(
                            _vm.contentPiece.instructions,
                            function (instruction, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "row" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "col-12" },
                                    [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(instruction.human_sort_order) +
                                            " " +
                                            _vm._s(instruction.title)
                                        ),
                                      ]),
                                      _c("div", {
                                        staticClass: "mb-2",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            instruction.description
                                          ),
                                        },
                                      }),
                                      _vm._l(
                                        instruction.uploads,
                                        function (image, instructionIndex) {
                                          return _c("img", {
                                            key: instructionIndex,
                                            staticClass: "border img-fluid",
                                            staticStyle: {
                                              width: "70px",
                                              margin: "0 10px 0 0",
                                              height: "auto",
                                            },
                                            attrs: {
                                              src: _vm.getThumbnailPath(
                                                image.hashed_name
                                              ),
                                              alt: image.hashed_name,
                                            },
                                            on: {
                                              error: _vm.replaceByDefaultThumb,
                                            },
                                          })
                                        }
                                      ),
                                      _c("hr"),
                                    ],
                                    2
                                  ),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.contentPiece.uploads.length > 0
                    ? _c("div", { staticClass: "col-6" }, [
                        _vm._m(17),
                        _c(
                          "div",
                          { staticClass: "row" },
                          _vm._l(
                            _vm.contentPiece.uploads,
                            function (image, cpUploadIndex) {
                              return _c(
                                "div",
                                { key: cpUploadIndex, staticClass: "col-3" },
                                [
                                  _c("img", {
                                    staticClass: "border img-fluid mb-4",
                                    attrs: {
                                      src: _vm.getThumbnailPath(
                                        image.hashed_name
                                      ),
                                      alt: image.hashed_name,
                                    },
                                    on: { error: _vm.replaceByDefaultThumb },
                                  }),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm.contentPiece.briefing_renderings.length > 0 ||
        _vm.contentPiece.briefing_show_flats.length > 0
          ? _c("div", { staticClass: "row my-4" }, [_vm._m(18)])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "row" },
          [
            _vm._l(
              _vm.contentPiece.briefing_renderings,
              function (briefingrendering, briefingrenderingIndex) {
                return _c("rendering-briefing-summary", {
                  key: "briefingrendering" + briefingrenderingIndex,
                  attrs: {
                    "content-piece": _vm.contentPiece,
                    briefing: briefingrendering,
                  },
                })
              }
            ),
            _vm._l(
              _vm.contentPiece.briefing_show_flats,
              function (briefingshowflat, briefingshowflatIndex) {
                return _c("rendering-briefing-summary", {
                  key: "briefingshowflat" + briefingshowflatIndex,
                  attrs: {
                    "content-piece": _vm.contentPiece,
                    briefing: briefingshowflat,
                  },
                })
              }
            ),
          ],
          2
        ),
        _vm.contentPiece.briefing_customer_instruction ||
        _vm.contentPiece.briefing_text ||
        _vm.contentPiece.briefing_video
          ? _c("div", { staticClass: "row my-4" }, [_vm._m(19)])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "row" },
          [
            _vm.contentPiece.briefing_customer_instruction
              ? _c("cola-briefing-summary", {
                  attrs: {
                    briefing: _vm.contentPiece.briefing_customer_instruction,
                    "content-piece": _vm.contentPiece,
                    title: "Anleitung",
                  },
                })
              : _vm._e(),
            _vm.contentPiece.briefing_text
              ? _c("cola-briefing-summary", {
                  attrs: {
                    briefing: _vm.contentPiece.briefing_text,
                    "content-piece": _vm.contentPiece,
                    title: "Text",
                  },
                })
              : _vm._e(),
            _vm.contentPiece.briefing_video
              ? _c("cola-briefing-summary", {
                  attrs: {
                    briefing: _vm.contentPiece.briefing_video,
                    "content-piece": _vm.contentPiece,
                    title: "Video",
                  },
                })
              : _vm._e(),
            _vm.contentPiece.briefing_stock_image
              ? _c("cola-briefing-summary", {
                  attrs: {
                    briefing: _vm.contentPiece.briefing_stock_image,
                    "content-piece": _vm.contentPiece,
                    title: "Stock image",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h2", [_vm._v("Zusammenfassung aktueller Stand")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-5" }, [_c("strong", [_vm._v("Name")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-5" }, [_c("strong", [_vm._v("J-ID")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-5" }, [
      _c("strong", [_vm._v("Topic")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-5" }, [_c("strong", [_vm._v("Team")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-5" }, [_c("strong", [_vm._v("Raum")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-5" }, [
      _c("strong", [_vm._v("Deadline")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-5" }, [
      _c("strong", [_vm._v("Ersteller")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-5" }, [
      _c("strong", [_vm._v("Datum der Erstellung:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 py-1" }, [
        _c("strong", [_vm._v("Artikelliste")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_vm._v("SKU")]), _c("th", [_vm._v("Qty")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 py-1" }, [
        _c("strong", [_vm._v("Optionen")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_vm._v("SKU")]), _c("th", [_vm._v("Qty")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 py-1" }, [
        _c("strong", [_vm._v("Hilfsmittel")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_vm._v("SKU")]), _c("th", [_vm._v("Qty")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("strong", [_vm._v("Background Informationen")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("strong", [_vm._v("Anleitung")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("strong", [_vm._v("Referenzen")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("h3", [_vm._v("3D Briefings")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("h3", [_vm._v("COLA Briefings")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }