var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.resultLoading ? _c("loading") : _vm._e(),
      _c("transition", { attrs: { name: "fadein" } }, [
        !_vm.resultLoading
          ? _c("div", [
              _c("div", [
                _c("div", { staticClass: "container" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("h1", { staticClass: "mb-4" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("i18n.headline_briefing")) + " "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 col-md-4" }, [
                      _c("div", { staticClass: "mb-4" }, [
                        _c(
                          "div",
                          { staticClass: "editable" },
                          [
                            _c("datepicker", {
                              attrs: {
                                errors: _vm.errors,
                                date: _vm.form.deadline,
                                settings: _vm.datepickerSettings,
                              },
                              on: { updateEvent: _vm.deadlineUpdated },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "container", attrs: { id: "br-details" } },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12 col-md-4" }, [
                            _c("div", { staticClass: "mb-4" }, [
                              _c(
                                "div",
                                { staticClass: "mb-0" },
                                [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.$t("i18n.label_uploads"))
                                    ),
                                  ]),
                                  _c("upload", {
                                    attrs: {
                                      "upload-path": "briefing-stock-images",
                                      "already-uploaded-files":
                                        _vm.briefing.uploads,
                                      "uploadable-id": _vm.id,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("i18n.label_annotation"))),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-12 col-lg-7" }, [
                      _c(
                        "div",
                        { staticClass: "editable mb-4 annotation" },
                        [
                          _c("editor", {
                            attrs: {
                              "initial-content": _vm.form.annotation,
                              placeholder: _vm.$t(
                                "i18n.placeholder_annotation"
                              ),
                              "active-buttons": [
                                "bold",
                                "strike",
                                "underline",
                                "bullet_list",
                                "ordered_list",
                              ],
                            },
                            on: { blur: _vm.editorUpdated },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }