var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("label", [_vm._v(_vm._s(_vm.settings.text_label))]),
      _c("date-picker", {
        staticClass: "d-block",
        attrs: {
          "input-class":
            _vm.getValidationClass(_vm.settings.validationClass) +
            " mx-input form-control",
          editable: false,
          type: _vm.settings.type,
          placeholder: _vm.settings.text_placeholder,
          format: _vm.dateFormat,
        },
        on: { change: _vm.deadlineUpdated },
        model: {
          value: _vm.formattedDate,
          callback: function ($$v) {
            _vm.formattedDate = $$v
          },
          expression: "formattedDate",
        },
      }),
      _vm.getErrorMsg(_vm.settings.validationClass)
        ? _c("div", {
            staticClass: "text-danger small",
            domProps: {
              innerHTML: _vm._s(_vm.getErrorMsg(_vm.settings.validationClass)),
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "form-text text-muted small" }, [
        _vm._v(" " + _vm._s(_vm.settings.text_helper) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }