var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ValidationObserver", { ref: "observer" }, [
        _c("h3", [_vm._v(_vm._s(_vm.settings.headlinePlaceholder))]),
        _c("span", {
          staticClass: "text-muted small",
          domProps: { innerHTML: _vm._s(_vm.settings.description) },
        }),
        _c(
          "div",
          { staticClass: "big-border px-3 pt-3" },
          [
            _vm._l(_vm.items, function (item, index) {
              return _c("div", { key: index, staticClass: "row listitem" }, [
                _c("div", { staticClass: "col-9 pr-0" }, [
                  _c("div", { staticClass: "form-group mb-2" }, [
                    _c(
                      "p",
                      {
                        staticClass: "transparent text-break",
                        attrs: { readonly: "" },
                      },
                      [_vm._v(" " + _vm._s(item) + " ")]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "col-3 text-right" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn text-danger",
                        on: {
                          click: function ($event) {
                            return _vm.deleteItem(index)
                          },
                        },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "trash"] },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ])
            }),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-9 pr-0" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("validation-provider", {
                      attrs: {
                        rules: _vm.settings.validationRules,
                        name: _vm.settings.itemName,
                        mode: "passive",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ errors }) {
                            return [
                              _c("div", { staticClass: "editable" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.inputField,
                                      expression: "inputField",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class: errors[0] ? "is-invalid" : "",
                                  attrs: {
                                    type: "text",
                                    placeholder: _vm.$t(
                                      "i18n.placeholder_add_new",
                                      { value: _vm.settings.itemName }
                                    ),
                                  },
                                  domProps: { value: _vm.inputField },
                                  on: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.addItem()
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.inputField = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                              _c("span", { staticClass: "invalid-feedback" }, [
                                _vm._v(_vm._s(errors[0])),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "col-3 text-right" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn additem",
                      on: {
                        click: function ($event) {
                          return _vm.addItem()
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "plus"] },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }