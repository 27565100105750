var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-form-checkbox",
        {
          on: {
            change: function ($event) {
              return _vm.emitDataToParent()
            },
          },
          model: {
            value: _vm.formatSelected,
            callback: function ($$v) {
              _vm.formatSelected = $$v
            },
            expression: "formatSelected",
          },
        },
        [_vm._v(" " + _vm._s(_vm.settings.name) + " ")]
      ),
      _vm.formatSelected
        ? _c(
            "div",
            { staticClass: "row mt-3 border-bottom pb-4" },
            [
              _vm._l(_vm.settings.orientations, function (orientation, index) {
                return _c(
                  "div",
                  {
                    key: _vm.settings.name + index,
                    staticClass: "col-4 col-md-2",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "orientationBox",
                        class: { selected: _vm.selectedOrientation === index },
                        on: {
                          click: function ($event) {
                            return _vm.confirmSwitchingOrientation(index)
                          },
                        },
                      },
                      [
                        _vm.selectedOrientation === index
                          ? _c("font-awesome-icon", {
                              attrs: { icon: ["fas", "check-circle"] },
                            })
                          : _vm._e(),
                        _c("div", { staticClass: "image" }, [
                          _c("img", {
                            staticClass: "img-fluid",
                            attrs: {
                              src: require(`@/assets/images/image_formats/${index}.jpg`),
                              alt: "",
                            },
                          }),
                        ]),
                        _c("p", { staticClass: "text-center mt-2" }, [
                          _c("strong", [_vm._v(_vm._s(orientation.name))]),
                        ]),
                      ],
                      1
                    ),
                  ]
                )
              }),
              _c("div", { staticClass: "col-7 col-md-4 col-lg-3 pt-4" }, [
                _c("div", { staticClass: "form-group row" }, [
                  _c("label", { staticClass: "col-4 col-form-label" }, [
                    _vm._v(_vm._s(_vm.$t("label_height"))),
                  ]),
                  _c("div", { staticClass: "col-7" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.height,
                          expression: "height",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control",
                      class: _vm.getValidationClass("height"),
                      attrs: { "data-image-format-height": "", type: "number" },
                      domProps: { value: _vm.height },
                      on: {
                        change: function ($event) {
                          return _vm.validateInput()
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.height = _vm._n($event.target.value)
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                    _c("div", {
                      staticClass: "invalid-feedback",
                      domProps: {
                        innerHTML: _vm._s(_vm.getErrorMsg("height")),
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "col-1 pl-0 col-form-label" }, [
                    _vm._v(" px "),
                  ]),
                ]),
                _c("div", { staticClass: "form-group row m-0" }, [
                  _c(
                    "label",
                    { staticClass: "col-4 col-form-label linked" },
                    [
                      _vm.selectedOrientation === "SQUARE"
                        ? _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "link"] },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "form-group row" }, [
                  _c("label", { staticClass: "col-4 col-form-label" }, [
                    _vm._v(_vm._s(_vm.$t("label_width"))),
                  ]),
                  _c("div", { staticClass: "col-7" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.width,
                          expression: "width",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control",
                      class: [
                        { readonly: _vm.selectedOrientation === "SQUARE" },
                        _vm.getValidationClass("width"),
                      ],
                      attrs: {
                        "data-image-format-width": "",
                        type: "number",
                        disabled: _vm.selectedOrientation === "SQUARE",
                      },
                      domProps: { value: _vm.width },
                      on: {
                        change: function ($event) {
                          return _vm.validateInput()
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.width = _vm._n($event.target.value)
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                    _c("div", {
                      staticClass: "invalid-feedback",
                      domProps: { innerHTML: _vm._s(_vm.getErrorMsg("width")) },
                    }),
                  ]),
                  _c("div", { staticClass: "col-1 pl-0 col-form-label" }, [
                    _vm._v(" px "),
                  ]),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "col-5 col-md-1 col-lg-2 text-right pt-4" },
                [_vm._v(" Dateityp: " + _vm._s(_vm.settings.extension) + " ")]
              ),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }