var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.resultLoading ? _c("loading") : _vm._e(),
      _c(
        "transition",
        { attrs: { name: "fadein" } },
        [
          !_vm.briefingRendering.ordered && !_vm.resultLoading
            ? _c("briefing-rendering-details-view", {
                attrs: {
                  id: _vm.id,
                  "briefing-rendering": _vm.briefingRendering,
                },
              })
            : !_vm.resultLoading
            ? _c("briefing-solo-details-print", {
                attrs: {
                  id: _vm.id,
                  briefing: _vm.briefingRendering,
                  "link-back-name": "BriefingRenderingList",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fadein" } },
        [
          !_vm.resultLoading && !_vm.briefingRendering.hasContentPiece
            ? _c("publish-briefing-solo-bar", {
                attrs: {
                  "briefing-id": _vm.briefingRendering.id,
                  ordered: _vm.briefingRendering.ordered,
                  "briefing-type": _vm.briefingRendering.briefingType,
                  "briefing-result-name": "Content Image Rendering(s)",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }