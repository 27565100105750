var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            "no-close-on-backdrop": "",
            "hide-footer": "",
            size: "lg",
            centered: "",
            title: _vm.$t("headline"),
          },
          model: {
            value: _vm.showModal,
            callback: function ($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.$t("description")) + " "),
          _c("hr"),
          _vm.errors.length > 0
            ? _c("div", [
                _c(
                  "ul",
                  { staticClass: "alert alert-danger unstyled-list" },
                  _vm._l(_vm.errors, function (error, index) {
                    return _c("li", { key: index }, [
                      _vm._v(" " + _vm._s(error) + " "),
                    ])
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            [
              _c("h4", [_vm._v(_vm._s(_vm.$t("label_headline")))]),
              _vm.briefing.deadline
                ? _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("moment")(_vm.briefing.deadline, "DD.MM.Y")
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
              _c("h4", [_vm._v(_vm._s(_vm.$t("label_articles")))]),
              _vm._l(_vm.productList, function (product, index) {
                return _c("div", { key: index, staticClass: "row mb-4 mt-4" }, [
                  _c("div", { staticClass: "col-2 text-center" }, [
                    _c(
                      "div",
                      { staticClass: "article-img-wrapper d-inline-block" },
                      [
                        _c("img", {
                          staticClass: "img-fluid",
                          attrs: { src: _vm.imgUrl(product.articleNumber) },
                          on: { error: _vm.imgPlaceholder },
                        }),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "col-4" }, [
                    _c("strong", [_vm._v(_vm._s(product.title))]),
                    _c("br"),
                    _vm._v(" " + _vm._s(product.nationalId) + " "),
                  ]),
                  _c("div", { staticClass: "col-3 row" }, [
                    _c("div", { staticClass: "col-6 px-2" }, [
                      _c(
                        "div",
                        { staticClass: "orderType selected" },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "check-circle"] },
                          }),
                          _c("div", { staticClass: "image" }, [
                            _c("img", {
                              staticClass: "img-fluid",
                              attrs: {
                                src: require("@/assets/images/briefing-3d-icon.png"),
                                alt: "",
                              },
                            }),
                          ]),
                          _c("p", { staticClass: "text-center mt-2 small" }, [
                            _vm._v(" 3D Model "),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-6 px-2" }, [
                      _c(
                        "div",
                        {
                          staticClass: "orderType",
                          class: { selected: _vm.briefing.createLowPoly },
                        },
                        [
                          _vm.briefing.createLowPoly
                            ? _c("font-awesome-icon", {
                                attrs: { icon: ["fas", "check-circle"] },
                              })
                            : _vm._e(),
                          _c("div", { staticClass: "image" }, [
                            _c("img", {
                              staticClass: "img-fluid",
                              attrs: {
                                src: require("@/assets/images/briefing-3d-icon.png"),
                                alt: "",
                              },
                            }),
                          ]),
                          _c("p", { staticClass: "text-center mt-2 small" }, [
                            _vm._v(" Low Poly "),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-3" }, [
                    product.briefingArticleId || product.briefingLowPolyId
                      ? _c(
                          "div",
                          { staticClass: "alert alert-danger small p-1" },
                          [
                            product.briefingArticleId
                              ? _c("span", { staticClass: "d-block" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("error_briefing_article_exists")
                                    )
                                  ),
                                ])
                              : _vm._e(),
                            product.briefingLowPolyId
                              ? _c("span", { staticClass: "d-block" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("error_briefing_low_poly_exists")
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ])
              }),
              _c("h4", [_vm._v(_vm._s(_vm.$t("label_references")))]),
              _c("uploads", {
                staticClass: "mb-4",
                attrs: {
                  "view-style": "Grid",
                  "uploaded-files": _vm.briefing.uploads,
                },
              }),
              _c("div", { staticClass: "text-white bg-primary" }, [
                _c("div", { staticClass: "container" }, [
                  _c("div", { staticClass: "row py-3" }, [
                    _c("div", { staticClass: "col-12 text-center" }, [
                      _c("p", { staticClass: "m-0 small" }, [
                        _vm._v(" " + _vm._s(_vm.$t("text_done")) + " "),
                        _c(
                          "button",
                          {
                            staticClass: "mx-2 btn btn-dark text-white btn-sm",
                            class: { disabled: _vm.errors.length > 0 },
                            attrs: {
                              id: "order-batch-briefing-button",
                              disabled: _vm.errors.length > 0,
                            },
                            on: { click: _vm.orderBatchBriefingArticle },
                          },
                          [
                            _vm._v(" " + _vm._s(_vm.$t("btn_send")) + " "),
                            _c("font-awesome-icon", {
                              staticClass: "mr-2",
                              attrs: { icon: ["fas", "paper-plane"] },
                            }),
                          ],
                          1
                        ),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.$t("text_continue_process")),
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }