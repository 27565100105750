var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "form-group mt-4 mb-4" }, [
        _vm.exceptionMessage
          ? _c("div", { staticClass: "alert alert-danger" }, [
              _vm._v(_vm._s(_vm.exceptionMessage)),
            ])
          : _vm._e(),
        _c("div", { staticClass: "editable" }, [
          _c("label", { attrs: { for: "project-name" } }, [
            _vm._v(_vm._s(_vm.$t("label_name"))),
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.name,
                expression: "form.name",
              },
            ],
            staticClass: "form-control",
            class: _vm.getValidationClass("name"),
            attrs: {
              id: "project-name",
              type: "text",
              maxlength: "80",
              autocomplete: "off",
            },
            domProps: { value: _vm.form.name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "name", $event.target.value)
              },
            },
          }),
          _c("div", {
            staticClass: "invalid-feedback",
            domProps: { innerHTML: _vm._s(_vm.getErrorMsg("name")) },
          }),
          _c("div", { staticClass: "form-text text-muted small" }, [
            _vm._v(" " + _vm._s(_vm.$t("helper_name")) + " "),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t("label_description")))]),
        ]),
        _c("div", { staticClass: "col-12 mb-3" }, [
          _c(
            "div",
            { staticClass: "editable mb-4 h-100" },
            [
              _c("editor", {
                attrs: {
                  "initial-content": _vm.form.description,
                  placeholder: _vm.$t("placeholder_description"),
                  "active-buttons": [
                    "bold",
                    "strike",
                    "underline",
                    "bullet_list",
                    "ordered_list",
                  ],
                },
                on: { blur: _vm.updateDescription },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("TeamSelect", {
        attrs: { errors: _vm.errors },
        on: { updateEvent: _vm.updateTeam },
        model: {
          value: _vm.form.team,
          callback: function ($$v) {
            _vm.$set(_vm.form, "team", $$v)
          },
          expression: "form.team",
        },
      }),
      _c("div", { staticClass: "row mb-4" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            { staticClass: "editable" },
            [
              _c("datepicker", {
                attrs: {
                  errors: _vm.errors,
                  date: _vm.form.deadline,
                  settings: _vm.datepickerSettings,
                },
                on: { updateEvent: _vm.deadlineUpdated },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "button",
        {
          staticClass: "btn btn-default float-right",
          on: {
            click: function ($event) {
              return _vm.save()
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("btn_save")) + " ")]
      ),
      _c("div", { staticClass: "clearfix" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }