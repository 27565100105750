var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "mb-5" }, [
        _c("div", { staticClass: "d-xl-flex flex-xl-row" }, [
          _c("div", { staticClass: "d-xl-inline-flex mb-5 mb-xl-0" }, [
            _c("div", { staticClass: "form-group mb-0" }, [
              _c(
                "div",
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        staticClass: "pr-4",
                        class: _vm.showNonProjectBriefingsFilter
                          ? "searchinput-md"
                          : "searchinput-lg",
                        attrs: { placeholder: _vm.$t("placeholder_search") },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.search()
                          },
                        },
                        model: {
                          value: _vm.searchQry,
                          callback: function ($$v) {
                            _vm.searchQry = $$v
                          },
                          expression: "searchQry",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _vm.searchQry
                            ? _c(
                                "b-button",
                                {
                                  staticClass:
                                    "bg-transparent text-danger border-0 delete",
                                  on: {
                                    click: function ($event) {
                                      return _vm.clearSearch()
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["fas", "times"] },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.search()
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "text-white",
                                attrs: { icon: ["fas", "search"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm.showNonProjectBriefingsFilter
              ? _c("div", { staticClass: "col-form-label ml-4" }, [
                  _c("div", { staticClass: "form-check" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.showNonProjectBriefings,
                          expression: "showNonProjectBriefings",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        id: "showNonProjectBriefings",
                        type: "checkbox",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.showNonProjectBriefings)
                          ? _vm._i(_vm.showNonProjectBriefings, null) > -1
                          : _vm.showNonProjectBriefings,
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$a = _vm.showNonProjectBriefings,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.showNonProjectBriefings = $$a.concat([
                                    $$v,
                                  ]))
                              } else {
                                $$i > -1 &&
                                  (_vm.showNonProjectBriefings = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.showNonProjectBriefings = $$c
                            }
                          },
                          function ($event) {
                            return _vm.search()
                          },
                        ],
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label font-weight-bold",
                        attrs: { for: "showNonProjectBriefings" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("label_only_non_project_articles")) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "d-block d-md-inline-flex ml-auto" }, [
            _c("div", { staticClass: "form-group row mb-5 mb-md-0 mr-md-3" }, [
              _c(
                "label",
                { staticClass: "col-7 col-form-label text-md-right" },
                [
                  _c("div", { staticClass: "text-nowrap" }, [
                    _vm._v(_vm._s(_vm.$t("label_entries_per_page"))),
                  ]),
                ]
              ),
              _c("div", { staticClass: "col-5" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.perPage,
                        expression: "perPage",
                      },
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.perPage = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", [_vm._v("10")]),
                    _c("option", [_vm._v("25")]),
                    _c("option", [_vm._v("50")]),
                    _c("option", [_vm._v("100")]),
                  ]
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "d-block d-md-inline-flex" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("b-pagination", {
                  staticClass: "m-0",
                  attrs: {
                    "total-rows": _vm.pagination.total,
                    "per-page": _vm.pagination.per_page,
                    limit: _vm.paginationLinksLimit,
                  },
                  model: {
                    value: _vm.currentPage,
                    callback: function ($$v) {
                      _vm.currentPage = $$v
                    },
                    expression: "currentPage",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._t("default"),
      _c("div", { staticClass: "mt-5" }, [
        _c("div", { staticClass: "d-xl-flex flex-xl-row" }, [
          _c("div", { staticClass: "ml-auto d-block d-md-inline-flex" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("b-pagination", {
                  staticClass: "m-0",
                  attrs: {
                    "total-rows": _vm.pagination.total,
                    "per-page": _vm.pagination.per_page,
                    limit: 25,
                  },
                  model: {
                    value: _vm.currentPage,
                    callback: function ($$v) {
                      _vm.currentPage = $$v
                    },
                    expression: "currentPage",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }