var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.error
      ? _c("div", { staticClass: "alert alert-danger" }, [
          _vm._v(" " + _vm._s(_vm.error) + " "),
        ])
      : _vm._e(),
    _c(
      "div",
      [
        _c(
          "b-dropdown",
          {
            staticClass: "float-right",
            attrs: { text: _vm.$t("btn_create"), variant: "outline-primary" },
          },
          [
            _c(
              "b-dropdown-item",
              {
                on: {
                  click: function ($event) {
                    return _vm.createBatch()
                  },
                },
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("option_batch_briefing_article")) + " "
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }