var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container", attrs: { id: "cp-details" } }, [
      _c("div", { staticClass: "row mb-3" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("h1", { staticClass: "mb-0" }, [
            _vm.form.product
              ? _c("div", [
                  _vm._v(" " + _vm._s(_vm.form.product.sdbProduct.title) + " "),
                ])
              : _c("div", [
                  _vm._v(
                    " " + _vm._s(_vm.$t("i18n.headline_briefingarticle")) + " "
                  ),
                ]),
          ]),
          _c("p", { staticClass: "mb-4" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.briefingArticle.product.sdbProduct
                    .nationalArticleIdInclCheckDigit
                ) +
                " "
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row mb-3" }, [
        _c("div", { staticClass: "col-12 col-md-4" }, [
          _c(
            "div",
            { staticClass: "editable" },
            [
              _c("datepicker", {
                attrs: {
                  errors: _vm.errors,
                  date: _vm.form.deadline,
                  settings: _vm.deadlineSettings,
                },
                on: { updateEvent: _vm.deadlineUpdated },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "col-12 col-md-3" }, [
          _c("div", { staticClass: "editable" }, [
            _c("label", [_vm._v(_vm._s(_vm.$t("i18n.headline_room")))]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.room,
                    expression: "form.room",
                  },
                ],
                staticClass: "form-control",
                class: _vm.getValidationClass("room"),
                attrs: { id: "cp-room" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.form,
                        "room",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                    function ($event) {
                      return _vm.roomUpdated($event)
                    },
                  ],
                },
              },
              [
                _c("option", [
                  _vm._v(_vm._s(_vm.$t("i18n.select_placeholder"))),
                ]),
                _vm._l(_vm.roomTypes, function (room) {
                  return _c(
                    "option",
                    { key: room, domProps: { value: room } },
                    [_vm._v(" " + _vm._s(room) + " ")]
                  )
                }),
              ],
              2
            ),
            _c("div", {
              staticClass: "invalid-feedback",
              domProps: { innerHTML: _vm._s(_vm.getErrorMsg("room")) },
            }),
          ]),
        ]),
        _c("div", { staticClass: "col-3 offset-0 offset-md-2 col-md-3" }, [
          _vm.form.product
            ? _c("img", {
                staticClass: "img-fluid",
                attrs: {
                  width: "250",
                  height: "250",
                  src: _vm.productImageURL(
                    _vm.form.product.sdbProduct.articleNumber
                  ),
                },
                on: { error: _vm.fallbackPlaceholder },
              })
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("hr", { staticClass: "my-5" }),
            _c("h3", [_vm._v(_vm._s(_vm.$t("i18n.headline_references")))]),
            _c("span", { staticClass: "text-muted small" }, [
              _c("p", [_vm._v(_vm._s(_vm.$t("i18n.helper_references")))]),
            ]),
            _c("upload", {
              attrs: {
                id: "upload-content-piece",
                "upload-path": "briefing-article",
                "view-style": "Grid",
                "already-uploaded-files": _vm.briefingArticle.uploads,
                "uploadable-id": _vm.briefingArticle.id,
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row pb-8" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("hr", { staticClass: "my-5" }),
          _c("h3", [_vm._v(_vm._s(_vm.$t("i18n.information")))]),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.information,
                expression: "form.information",
              },
            ],
            staticClass: "mb-4 form-control",
            attrs: {
              rows: "10",
              maxlength: "500",
              placeholder: _vm.$t("i18n.information_placeholder"),
            },
            domProps: { value: _vm.form.information },
            on: {
              change: _vm.updateBriefing,
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "information", $event.target.value)
              },
            },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }