var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.resultLoading ? _c("loading") : _vm._e(),
      _c("transition", { attrs: { name: "fadein" } }, [
        !_vm.resultLoading
          ? _c("div", [
              _c("div", [
                _c("div", { staticClass: "container" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("h1", { staticClass: "mb-4" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("i18n.headline_briefing")) + " "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 col-md-4" }, [
                      _c("div", { staticClass: "mb-4" }, [
                        _c(
                          "div",
                          { staticClass: "editable" },
                          [
                            _c("datepicker", {
                              attrs: {
                                errors: _vm.errors,
                                date: _vm.form.deadline,
                                settings: _vm.datepickerSettings,
                              },
                              on: { updateEvent: _vm.deadlineUpdated },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "container", attrs: { id: "br-details" } },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("div", { staticClass: "mb-4" }, [
                              _c("div", { staticClass: "mb-0" }, [
                                _c("label", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("i18n.label_select_instructions")
                                    )
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "options" },
                                  _vm._l(
                                    _vm.form.options,
                                    function (instruction, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "form-check",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: instruction.checked,
                                                expression:
                                                  "instruction.checked",
                                              },
                                            ],
                                            staticClass: "form-check-input",
                                            attrs: {
                                              id: "instr" + index,
                                              type: "checkbox",
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                instruction.checked
                                              )
                                                ? _vm._i(
                                                    instruction.checked,
                                                    null
                                                  ) > -1
                                                : instruction.checked,
                                            },
                                            on: {
                                              change: [
                                                function ($event) {
                                                  var $$a = instruction.checked,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          instruction,
                                                          "checked",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          instruction,
                                                          "checked",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      instruction,
                                                      "checked",
                                                      $$c
                                                    )
                                                  }
                                                },
                                                function ($event) {
                                                  return _vm.updateBriefing()
                                                },
                                              ],
                                            },
                                          }),
                                          _c(
                                            "label",
                                            {
                                              staticClass: "form-check-label",
                                              attrs: { for: "instr" + index },
                                            },
                                            [_vm._v(_vm._s(instruction.title))]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-text text-muted small" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "i18n.helper_select_instructions"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("i18n.label_annotation"))),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-12 col-lg-7" }, [
                      _c(
                        "div",
                        { staticClass: "editable mb-4 annotation" },
                        [
                          _c("editor", {
                            attrs: {
                              "initial-content": _vm.form.annotation,
                              placeholder: _vm.$t(
                                "i18n.placeholder_annotation"
                              ),
                              "active-buttons": [
                                "bold",
                                "strike",
                                "underline",
                                "bullet_list",
                                "ordered_list",
                              ],
                            },
                            on: { blur: _vm.editorUpdated },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }