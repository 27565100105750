var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container position-relative pb-4" },
    [
      _c("add-briefing-button", { attrs: { "briefing-type": "project" } }),
      _c(
        "search-and-pagination",
        {
          attrs: { "vuex-module-name": "Project" },
          on: { loading: _vm.setLoading },
        },
        [
          _vm.resultLoading ? _c("loading") : _vm._e(),
          !_vm.resultLoading
            ? _c(
                "transition-group",
                { attrs: { name: "fade" } },
                _vm._l(_vm.projects, function (project, index) {
                  return _c("project-single-line", {
                    key: index,
                    attrs: { project: project },
                  })
                }),
                1
              )
            : _vm._e(),
          !_vm.resultLoading && _vm.projects.length === 0
            ? _c("p", { staticClass: "text-center text-bold mt-5" }, [
                _vm._v(" - Keine Einträge gefunden - "),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }