var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("sd-search-wrapper", {
    key: _vm.language,
    staticClass: "article-broker hydrated",
    attrs: { namespace: _vm.getIassNamespace, language: _vm.language },
    on: { selectedArticles: _vm.addProductFromSearch },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }