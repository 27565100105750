var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fadeicon" } }, [
    _vm.showIcon
      ? _c("div", { staticClass: "circle-loader", class: _vm.loaderClass }, [
          _vm.showCompleted
            ? _c("div", { staticClass: "checkmark draw" })
            : _vm._e(),
          _vm.showFailed
            ? _c("div", { staticClass: "failed" }, [_vm._v(" ! ")])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }