var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "text-white",
      class: _vm.setBackground(),
      attrs: { id: "briefing-navigation" },
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row py-3" }, [
          !_vm.ordered && _vm.readyToFinalize
            ? _c("div", { staticClass: "col-12 text-center" }, [
                !_vm.error
                  ? _c("p", { staticClass: "m-0" }, [
                      _vm._v(" " + _vm._s(_vm.$t("i18n.text_done")) + " "),
                      _c(
                        "button",
                        {
                          staticClass: "mx-2 btn btn-dark text-white btn-sm",
                          on: { click: _vm.exportContentPieces },
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.$t("i18n.btn_send")) + " "),
                          _c("font-awesome-icon", {
                            staticClass: "mr-2",
                            attrs: { icon: ["fas", "paper-plane"] },
                          }),
                        ],
                        1
                      ),
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("i18n.text_continue_process")
                          ),
                        },
                      }),
                    ])
                  : _c("p", { staticClass: "m-0" }, [
                      _vm._v(" Error: " + _vm._s(_vm.error) + " "),
                    ]),
              ])
            : _vm.ordered
            ? _c("div", { staticClass: "col-12 text-center" }, [
                _c("p", { staticClass: "m-0" }, [
                  _vm._v(" " + _vm._s(_vm.$t("i18n.text_ordered")) + " "),
                ]),
              ])
            : _c("div", { staticClass: "col-12 text-center" }, [
                _c("p", { staticClass: "m-0" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("i18n.not_ready_to_finalize")) + " "
                  ),
                ]),
              ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }