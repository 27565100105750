var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-12 col-md-6" }, [
    _c(
      "div",
      [
        _c("label", [_vm._v("Wähle ein Bild als Grundlage")]),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.activeFilters.Raum,
                expression: "activeFilters.Raum",
              },
            ],
            staticClass: "form-control mb-3",
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.activeFilters,
                    "Raum",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
                function ($event) {
                  return _vm.filterFavorite()
                },
              ],
            },
          },
          [
            _c("option", { attrs: { value: "" } }, [
              _vm._v(" Wähle einen Raum "),
            ]),
            _vm._l(_vm.favoriteFilter.Raum, function (filter) {
              return _c(
                "option",
                { key: filter, domProps: { value: filter } },
                [_vm._v(" " + _vm._s(filter) + " ")]
              )
            }),
          ],
          2
        ),
        _c("b-pagination", {
          attrs: {
            "total-rows": _vm.filteredFavorites.total,
            "per-page": _vm.pagination.itemsPerPage,
            limit: 20,
            "aria-controls": "my-table",
          },
          model: {
            value: _vm.pagination.currentPage,
            callback: function ($$v) {
              _vm.$set(_vm.pagination, "currentPage", $$v)
            },
            expression: "pagination.currentPage",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.activePage, function (favorite, index) {
        return _c(
          "div",
          {
            directives: [
              {
                name: "lazy-container",
                rawName: "v-lazy-container",
                value: { selector: "img" },
                expression: "{ selector: 'img' }",
              },
            ],
            key: index,
            staticClass: "col-6 col-md-4 mb-4 showflatgallery",
          },
          [
            favorite.acdcAssetId
              ? _c("img", {
                  staticClass: "img-fluid border",
                  class: _vm.isActiveShowFlat(favorite.id),
                  attrs: {
                    "data-src": _vm.getAcdcAssetURL(favorite),
                    "data-loading": _vm.LoadingThumb,
                    alt: favorite.baseName,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.selectShowFlat(favorite)
                    },
                  },
                })
              : _vm._e(),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }