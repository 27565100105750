var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.keycloakError
        ? _c("div", { staticClass: "alert-danger alert" }, [
            _vm._v(" " + _vm._s(_vm.keycloakError) + " "),
          ])
        : _c(
            _vm.layout,
            { tag: "component" },
            [
              _c(
                "transition",
                { attrs: { name: _vm.transitionName, mode: "out-in" } },
                [_c("router-view", { key: _vm.$route.fullPath })],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }