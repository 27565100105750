var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "mt-3" }, [
    _vm.canCreateBriefingLowpoly
      ? _c(
          "div",
          {
            staticClass: "btn btn-sm btn-primary text-white",
            on: {
              click: function ($event) {
                return _vm.openLowpolyModal()
              },
            },
          },
          [_vm._v("Neues Lowpoly Model briefen ")]
        )
      : _vm._e(),
    _vm.briefing.briefingLowPoly && _vm.briefing.briefingLowPoly.ordered
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4" }, [
            _vm._v(" Low Poly Model Briefing "),
          ]),
          _vm.briefing.briefingLowPoly.jira_export
            ? _c("div", { staticClass: "col-8" }, [
                _c(
                  "a",
                  {
                    staticClass: "link",
                    attrs: {
                      target: "_blank",
                      href: _vm.jiraURL(_vm.briefing.briefingLowPoly),
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.briefing.briefingLowPoly.jira_export.response.key
                      )
                    ),
                  ]
                ),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.briefing.briefingLowPoly.jira_export.status != null
                        ? "- " +
                            _vm.briefing.briefingLowPoly.jira_export.status.name
                        : ""
                    ) +
                    " "
                ),
              ])
            : _c("div", { staticClass: "col-8" }, [
                _vm._v(" Waiting for jira ticket status "),
              ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }