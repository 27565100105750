var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.errorMessage
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v(" " + _vm._s(_vm.errorMessage) + " "),
          ])
        : _vm._e(),
      _c(
        "draggable",
        {
          attrs: { handle: ".handle" },
          on: { change: _vm.instructionsSorted },
          model: {
            value: _vm.instructionList,
            callback: function ($$v) {
              _vm.instructionList = $$v
            },
            expression: "instructionList",
          },
        },
        [
          _c(
            "transition-group",
            _vm._l(_vm.instructionList, function (instruction, index) {
              return _c(
                "div",
                { key: instruction.id, staticClass: "mb-3 instruction-item" },
                [
                  _c("div", { staticClass: "row instruction" }, [
                    _c("div", { staticClass: "col-7" }, [
                      _c("div", { staticClass: "editable mb-3" }, [
                        _c("h5", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: instruction.title,
                                expression: "instruction.title",
                              },
                            ],
                            staticClass: "transparent w-100",
                            class: { "my-3": instruction.title },
                            attrs: { name: "title", type: "text" },
                            domProps: { value: instruction.title },
                            on: {
                              change: function ($event) {
                                return _vm.update(instruction)
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  instruction,
                                  "title",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "row instruction" }, [
                    _c("div", { staticClass: "col-1 text-center my-auto" }, [
                      _c("span", { staticClass: "bg-third step text-white" }, [
                        _vm._v(_vm._s(index + 1)),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-6" }, [
                      _c(
                        "div",
                        { staticClass: "editable h-100" },
                        [
                          _c("resizeable-textarea", [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: instruction.description,
                                  expression: "instruction.description",
                                },
                              ],
                              staticClass: "w-100 h-100 resize-none",
                              attrs: {
                                placeholder: _vm.$t("i18n.helper_description"),
                              },
                              domProps: { value: instruction.description },
                              on: {
                                change: function ($event) {
                                  return _vm.update(instruction)
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    instruction,
                                    "description",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("upload", {
                          attrs: {
                            "upload-path": "instructions",
                            "already-uploaded-files": instruction.uploads,
                            "uploadable-id": instruction.id,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "col-1 text-center" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn text-white btn-danger delete-instruction",
                          on: {
                            click: function ($event) {
                              return _vm.deleteInstruction(
                                instruction.id,
                                index
                              )
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "trash"] },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-1 text-center" }, [
                      _c(
                        "div",
                        { staticClass: "btn btn-outline-third handle" },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "bars"] },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
      _c("hr"),
      _c("div", { staticClass: "row instruction new-instruction" }, [
        _c("div", { staticClass: "col-9 offset-1" }, [
          _c("div", { staticClass: "editable mb-3" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.newInstruction.title,
                  expression: "newInstruction.title",
                },
              ],
              staticClass: "w-100",
              attrs: {
                id: "new_headline",
                type: "text",
                placeholder: _vm.$t("i18n.helper_headline"),
              },
              domProps: { value: _vm.newInstruction.title },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.newInstruction, "title", $event.target.value)
                },
              },
            }),
          ]),
          _c(
            "div",
            { staticClass: "editable" },
            [
              _c("resizeable-textarea", [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.newInstruction.description,
                      expression: "newInstruction.description",
                    },
                  ],
                  staticClass: "w-100 resize-none",
                  attrs: {
                    id: "new_instruction",
                    placeholder: _vm.$t("i18n.helper_description"),
                  },
                  domProps: { value: _vm.newInstruction.description },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.newInstruction,
                        "description",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "col-2 my-auto" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary text-white add_new_instruction",
              on: { click: _vm.addInstruction },
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("i18n.button_add_instruction")) + " "),
              _c("font-awesome-icon", { attrs: { icon: ["fas", "plus"] } }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }