var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "new-briefing-modal",
      attrs: {
        id: "new-briefing-lowpoly",
        "no-close-on-backdrop": "",
        "hide-footer": "",
        centered: "",
        title: _vm.$t("i18n.headline_create_briefing"),
      },
    },
    [
      _c("briefing-lowpoly-form", {
        attrs: { "briefing-article-id": _vm.briefingArticleId },
        on: {
          hideModal: function ($event) {
            return _vm.hideModal()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }