var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    _vm._l(_vm.formatSettings, function (formatSetting, index) {
      return _c(
        "div",
        { key: formatSetting.name + index, staticClass: "row mb-4" },
        [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("image-format-select", {
                attrs: {
                  settings: formatSetting,
                  "format-values": _vm.getValuesForFormat(formatSetting.name),
                },
                on: {
                  createOrUpdateFormat: _vm.createOrUpdateFormat,
                  deleteFormat: _vm.deleteFormat,
                },
              }),
            ],
            1
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }